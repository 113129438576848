import React, { useState, useEffect } from 'react';
import { ArrowsPointingOutIcon, DocumentTextIcon, CubeIcon, ArrowDownCircleIcon } from '@heroicons/react/24/outline'; // Import DownloadIcon
import TagsWindow from './TagsWindow';
import LoadingSpinner from './LoadingSpinner';
import StudyModel3D from './StudyModel3D'; // Import the new component

const StudyModal = ({ isOpen, onClose, images = [], selectedImage, setSelectedImage, viewMode = 'grid', setViewMode, tags = [] }) => {
  const [currentFrame, setCurrentFrame] = useState(0);
  const [isTagsWindowOpen, setIsTagsWindowOpen] = useState(false);
  const [frames, setFrames] = useState([]);
  const [loading, setLoading] = useState(false);
  const [show3D, setShow3D] = useState(false); // State for displaying the 3D model

  useEffect(() => {
    setCurrentFrame(0);
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    };
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isOpen]);

  useEffect(() => {
    if (viewMode === 'single' && frames.length > 1) {
      setCurrentFrame(Math.floor(frames.length / 2));
    }
  }, [frames, viewMode]);

  useEffect(() => {
    if (selectedImage) {
      const numberOfFrames = extractNumberOfFrames(tags[images.indexOf(selectedImage)]);
      if (numberOfFrames === 1) {
        setFrames([selectedImage]);
      } else if (numberOfFrames > 1) {
        setLoading(true);
        fetchFrames();
      };
    };
  }, [selectedImage]);

  const fetchFrames = async () => {
    const sopInstanceUid = extractSopInstanceUid(tags[images.indexOf(selectedImage)]);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/getmultiframe?sopInstanceUid=${sopInstanceUid}`);
      const data = await response.json();
      const base64Data = data.images[0];

      const splitFrames = base64Data.split('/9j/').filter(frame => frame.trim() !== '').map(frame => '/9j/' + frame);
      if (splitFrames.length > 0) {
        setFrames(splitFrames);
      } else {
        setFrames(['/9j/' + base64Data]);
      };

      console.log(`Number of frames collected: ${splitFrames.length}`);
    } catch (error) {
      console.error("Error fetching multiframe data:", error);
      setFrames([selectedImage]);
    } finally {
      setLoading(false);
    };
  };

  const extractTag = (tag, key) => (tag && tag[key]) ? tag[key] : ' ';
  const extractNumberOfFrames = (tag) => {
    const frames = tag && tag["(0028,0008) IS Number of Frames"];
    return frames ? parseInt(frames, 10) : 1;
  };

  const extractSopInstanceUid = (tag) => tag["(0008,0018) UI SOP Instance UID"];

  const extractImageAttributes = (tag) => {
    const columns = parseInt(extractTag(tag, "(0028,0011) US Columns"), 10);
    const rows = parseInt(extractTag(tag, "(0028,0010) US Rows"), 10);
    const numberOfFrames = extractNumberOfFrames(tag);
    const photometricInterpretation = extractTag(tag, "(0028,0004) CS Photometric Interpretation");

    let numComps;
    switch (photometricInterpretation) {
      case "RGB":
        numComps = 3;
        break;
      case "ARGB":
        numComps = 4;
        break;
      case "MONOCHROME1":
      case "MONOCHROME2":
      default:
        numComps = 1;
        break;
    }

    return { columns, rows, numberOfFrames, numComps, photometricInterpretation };
  };

  const handleWheel = (event) => {
    if (viewMode === 'single' && frames.length > 1) {
      if (event.deltaY > 0) {
        setCurrentFrame((prev) => (prev < frames.length - 1 ? prev + 1 : 0));
      } else {
        setCurrentFrame((prev) => (prev > 0 ? prev - 1 : frames.length - 1));
      };
    };
  };

  const displayFrame = (frameIndex) => frames[frameIndex] || frames[0];

  const toggleTagsWindow = () => {
    setIsTagsWindowOpen(!isTagsWindowOpen);
  };

  const closeTagsWindow = () => {
    setIsTagsWindowOpen(false);
  };

  const handleDownload = async () => {
    const sopInstanceUid = extractSopInstanceUid(tags[images.indexOf(selectedImage)]);
    const patientName = extractTag(tags[images.indexOf(selectedImage)], "(0010,0010) PN Patient's Name");
    const patientId = extractTag(tags[images.indexOf(selectedImage)], "(0010,0020) LO Patient ID");
  
    const response = await fetch(`${process.env.REACT_APP_API_URL}/getstudydcm?sopInstanceUid=${sopInstanceUid}`);
    
    if (response.ok) {
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = `${patientName}_${patientId}.dcm`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } else {
      console.error('Error downloading the file');
    }
  };
  
  if (!isOpen) return null;

  const calculateGridColumns = (length) => {
    const columns = Math.ceil(Math.sqrt(length));
    const rows = Math.ceil(length / columns);
    return { columns, rows };
  };

  const { columns, rows } = calculateGridColumns(images.length);

  const selectedTag = tags[images.indexOf(selectedImage)];
  const { columns: width, rows: height, numberOfFrames: depth, numComps, photometricInterpretation } = extractImageAttributes(selectedTag);
  const dataType = photometricInterpretation === 'RGB' || photometricInterpretation === 'ARGB' ? "unsigned_char" : "unsigned_short";

  return (
    <div className="fixed inset-0 h-screen z-50 flex items-center justify-center bg-black" onWheel={handleWheel}>
      <button className="fixed top-0 right-2 text-white text-4xl z-50" onClick={onClose}>
        &times;
      </button>
      <div className="absolute top-0 right-16 mt-2 mr-2 flex space-x-2 z-50">
        <button onClick={toggleTagsWindow} className="text-white bg-gray-800 p-2 rounded-full">
          <DocumentTextIcon className="h-6 w-6" />
        </button>
        {frames.length > 1 && (
          <button onClick={() => setShow3D(true)} className="text-white bg-gray-800 p-2 rounded-full">
            <CubeIcon className="h-6 w-6" />
          </button>
        )}
        <button onClick={handleDownload} className="text-white bg-gray-800 p-2 rounded-full">
          <ArrowDownCircleIcon className="h-6 w-6" />
        </button>
      </div>
      <div className="flex flex-col items-center justify-center w-full h-full px-10 overflow-hidden">
        {loading ? (
          <LoadingSpinner />
        ) : (
          images.length > 0 && (
            viewMode === 'grid' ? (
              <div className="grid w-full h-full" style={{ gridTemplateColumns: `repeat(${columns}, 1fr)`, gridTemplateRows: `repeat(${rows}, 1fr)` }}>
                {images.map((image, index) => {
                  const frames = image.split('/9j/');
                  const middleFrame = Math.floor(frames.length / 2);
                  const displayFrame = frames.length > 1 ? '/9j/' + frames[middleFrame] : image;

                  return (
                    <div key={index} className="relative group border border-blue-500">
                      <img
                        src={`data:image/jpeg;base64,${displayFrame}`}
                        alt={`DICOM ${index}`}
                        className="w-full h-full object-contain"
                        style={{ maxHeight: `calc(100vh / ${rows})`, maxWidth: `calc(100vw / ${columns})` }}
                      />
                      <button
                        onClick={() => {
                          setSelectedImage(image);
                          setViewMode('single');
                        }}
                        className="absolute bottom-0 right-0 m-2 text-white bg-black bg-opacity-50 rounded-full p-1"
                      >
                        <ArrowsPointingOutIcon className="h-6 w-6" />
                      </button>
                      <div className="absolute top-0 right-0 mr-2 mt-2 text-right text-white text-sm textarea-bordered rounded-tl-lg">
                        <div>{extractTag(tags[images.indexOf(image)], "(0008,0060) CS Modality")}</div>
                        <div>{extractTag(tags[images.indexOf(image)], "(0008,103e) LO Series Description")}</div>
                        <div>{extractTag(tags[images.indexOf(image)], "(0020,0062) CS Image Laterality")}</div>
                        <div>{extractTag(tags[images.indexOf(image)], "(0028,0004) CS Photometric Interpretation")}</div>
                        <div>{extractTag(tags[images.indexOf(image)], "(0018,1000) LO Device Serial Number")}</div>
                        <div>{extractTag(tags[images.indexOf(image)], "(0008,1090) LO Manufacturer's Model Name")}</div>
                        <div>{extractTag(tags[images.indexOf(image)], "(0008,0080) LO Institution Name")}</div>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="relative w-full h-full flex items-center justify-center">
                <img
                  src={`data:image/jpeg;base64,${displayFrame(currentFrame)}`}
                  alt="Selected DICOM"
                  className="max-w-full max-h-screen object-contain"
                />
                {frames.length > 1 && (
                  <>
                    <div className="absolute bottom-0 right-0 m-4 text-white text-4xl bg-black bg-opacity-50 p-2 rounded">
                      {currentFrame + 1} / {frames.length}
                    </div>
                    <input
                      type="range"
                      min="0"
                      max={frames.length - 1}
                      value={currentFrame}
                      onChange={(e) => setCurrentFrame(Number(e.target.value))}
                      className="absolute bottom-0 w-full"
                    />
                  </>
                )}
              </div>
            )
          )
        )}
      </div>
      {isTagsWindowOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg overflow-auto">
            <button className="absolute top-0 right-0 m-4 text-black text-2xl" onClick={closeTagsWindow}>
              &times;
            </button>
            <TagsWindow tags={Object.entries(tags[images.indexOf(selectedImage)]).map(([key, value]) => `${key}: ${value}`)} />
          </div>
        </div>
      )}
      {show3D && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-3/4 h-3/4 flex flex-col">
            <button className="absolute top-0 right-0 m-4 text-black text-2xl" onClick={() => setShow3D(false)}>
              &times;
            </button>
            <StudyModel3D
              width={width} // Provide appropriate width
              height={height} // Provide appropriate height
              depth={depth} // Provide appropriate depth
              numComps={numComps} // Provide appropriate number of components
              dataType={dataType} // Provide appropriate data type
              base64Data={selectedImage} // Pass the selected image in base64 format
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default StudyModal;
