import React, { useState } from 'react';
import StudyModal from './StudyModal';

const MasonryGallery = ({ images = [], tags = [], isOpen, onClose }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [viewMode, setViewMode] = useState('grid');

  const openModal = (images, image = null) => {
    setSelectedImages(images);
    setSelectedImage(image || images[0]);
    setIsModalOpen(true);
    setViewMode(image ? 'single' : 'grid');
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleExpand = (image, tag) => {
    setSelectedImage(image);
    const numberOfFrames = extractNumberOfFrames(tag);
    setViewMode(numberOfFrames > 1 ? 'single' : 'single');
    setIsModalOpen(true);
  };

  const extractSeriesInstanceUid = (tag) => tag["(0020,000e) UI Series Instance UID"];
  const extractSopInstanceUid = (tag) => tag["(0008,0018) UI SOP Instance UID"];
  const extractModelName = (tag) => tag["(0008,1090) LO Manufacturer's Model Name"];
  const extractStudyTime = (tag) => {
    const time = tag["(0008,0030) TM Study Time"];
    return time ? time.slice(0, 2) + ':' + time.slice(2, 4) : '';
  };
  const extractLaterality = (tag) => tag["(0020,0062) CS Image Laterality"];
  const extractNumberOfFrames = (tag) => {
    const frames = tag && tag["(0028,0008) IS Number of Frames"];
    return frames ? parseInt(frames, 10) : 1;
  };

  const groupedImages = images.reduce((acc, image, index) => {
    const seriesInstanceUid = tags[index] && extractSeriesInstanceUid(tags[index]);
    const sopInstanceUid = tags[index] && extractSopInstanceUid(tags[index]);
    if (seriesInstanceUid && sopInstanceUid) {
      if (!acc[seriesInstanceUid]) {
        acc[seriesInstanceUid] = {
          images: [],
          modelName: extractModelName(tags[index]),
          studyTime: extractStudyTime(tags[index]),
          laterality: extractLaterality(tags[index]),
          numberOfFrames: extractNumberOfFrames(tags[index]),
          tag: tags[index], // Añadir los tags completos aquí
        };
      }
      acc[seriesInstanceUid].images.push({ image, sopInstanceUid });
    }
    return acc;
  }, {});

  return (
    <div className="w-full h-full p-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2">
      {Object.entries(groupedImages).map(([seriesInstanceUid, group], index) => {
        const { images: groupImages, modelName, studyTime, laterality, numberOfFrames, tag } = group;
        groupImages.sort((a, b) => b.sopInstanceUid.localeCompare(a.sopInstanceUid));

        return (
          <div
            key={index}
            className="relative cursor-pointer group w-[300px] h-[300px]"
            onClick={() => openModal(groupImages.map(img => img.image))}
          >
            {groupImages.slice(0, 3).map((imgObj, i) => {
              const frames = imgObj.image.split('/9j/');
              const middleFrame = Math.floor(frames.length / 2);
              const displayFrame = frames.length > 1 ? '/9j/' + frames[middleFrame] : '/9j/' + imgObj.image;

              return (
                <div
                  key={i}
                  className={`absolute transition-transform transform hover:scale-105`}
                  style={{
                    zIndex: groupImages.length - i,
                    transform: `translate(${i * + 20}px, ${i * 20}px) scale(${1 + i * 0.01})`
                  }}
                >
                  {i === 0 && (
                    <div className="absolute bottom-0 bg-opacity-80 bg-slate-500 p-2 rounded-t-lg w-full text-right">
                      <div>{laterality}</div>
                      <div>{studyTime}</div>
                      <div className="font-bold uppercase">{modelName}</div>
                    </div>
                  )}
                  <img
                    src={`data:image/jpeg;base64,${displayFrame}`}
                    alt={`img-${index}-${i}`}
                    className="w-full h-full object-cover rounded-lg shadow-lg"
                    style={{ width: '300px', height: '200px' }}
                  />
                  <button
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent triggering the parent div's onClick
                      handleExpand(imgObj.image, tag);
                    }}
                    className="absolute bottom-0 right-0 m-2 text-white
                    "
                  >
                  </button>
                </div>
              );
            })}
          </div>
        );
      })}

      {isModalOpen && (
        <StudyModal
          isOpen={isModalOpen}
          onClose={closeModal}
          images={selectedImages}
          selectedImage={selectedImage}
          setSelectedImage={setSelectedImage} // Pasar el setter de selectedImage
          viewMode={viewMode}
          setViewMode={setViewMode} // Pasar el setter de viewMode
          tags={selectedImages.map((img, idx) => tags[images.indexOf(img)])} // Pasar los tags correspondientes
        />
      )}
    </div>
  );
};

export default MasonryGallery;
