import React from 'react';
import ReactDOM from 'react-dom';

const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 overflow-y-auto">
      <div className="relative bg-white rounded-lg m-10 p-10 shadow-xl transform transition-all max-w-4xl w-fulls">
        <button className="absolute top-0 right-0 mr-4 mt-4 text-black text-2xl" onClick={onClose}>
          &times;
        </button>
        <div className="overflow-auto  max-h-screen">
          {children}
        </div>
      </div>
    </div>,
    document.body
  );
};

export default Modal;