import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './Components/AuthContext';
import ProtectedRoute from './Components/ProtectedRoute';
import Home from './Pages/Home';
import Dashboard from './Pages/Dashboard';
import Server from './Pages/Server';
import Settings from './Pages/Settings';
import Profile from './Pages/Profile';
import Portfolio from './Pages/Portfolio';
import About from './Pages/About';
import LoginPage from './Pages/Login';
import Last7DaysStudiesPage from './Pages/Studies/Last7DaysStudies';
import TodayStudiesPage from './Pages/Studies/TodayStudies';
import TodayWorklistPage from './Pages/Worklist/TodayWorklist';
import HistoricWorklistPage from './Pages/Worklist/HistoricWorklist';
import Last7DaysWorklistPage from './Pages/Worklist/Last7DaysWorklist';
import Last7DaysPatientsPage from './Pages/Patients/Last7DaysPatients';
import HistoricPatientsPage from './Pages/Patients/HistoricPatients';
import TodayPatientsPage from './Pages/Patients/TodayPatients';
import HistoricStudiesPage from './Pages/Studies/HistoricStudies';
import Clients from './Pages/Clients';
import SearchPatientForm from './Windows/SearchPatientForm';
import SearchWorklistPage from './Pages/Worklist/SearchWorklist';
import SearchPatientPage from './Pages/Patients/SearchPatientsPage';
import CalendarPage from './Pages/Calendar';
import ChatPage from './Pages/Chat';

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/" element={<LoginPage />} />
          <Route path="/searchpatients" element={<ProtectedRoute element={<SearchPatientPage />} />} />
          <Route path="/queryworklist" element={<ProtectedRoute element={<SearchWorklistPage />} />} />
          <Route path="/listclient" element={<ProtectedRoute element={<Clients />} />} />
          <Route path="/chat" element={<ProtectedRoute element={<ChatPage />} />} />
          <Route path="/calendar" element={<ProtectedRoute element={<CalendarPage />} />} />
          <Route path="/home" element={<ProtectedRoute element={<Home />} />} />
          <Route path="/dashboard" element={<ProtectedRoute element={<Dashboard />} />} />
          <Route path="/server" element={<ProtectedRoute element={<Server />} />} />
          <Route path="/search-patient" element={<ProtectedRoute element={<SearchPatientForm />} />} />
          <Route path="/todaypatients" element={<ProtectedRoute element={<TodayPatientsPage />} />} />
          <Route path="/last7dayspatients" element={<ProtectedRoute element={<Last7DaysPatientsPage />} />} />
          <Route path="/historicpatients" element={<ProtectedRoute element={<HistoricPatientsPage />} />} />
          <Route path="/todayworklist" element={<ProtectedRoute element={<TodayWorklistPage />} />} />
          <Route path="/last7daysworklist" element={<ProtectedRoute element={<Last7DaysWorklistPage />} />} />
          <Route path="/historicworklist" element={<ProtectedRoute element={<HistoricWorklistPage />} />} />
          <Route path="/todaystudies" element={<ProtectedRoute element={<TodayStudiesPage />} />} />
          <Route path="/last7daysstudies" element={<ProtectedRoute element={<Last7DaysStudiesPage />} />} />
          <Route path="/historicstudies" element={<ProtectedRoute element={<HistoricStudiesPage />} />} />
          <Route path="/settings" element={<ProtectedRoute element={<Settings />} />} />
          <Route path="/profile" element={<ProtectedRoute element={<Profile />} />} />
          <Route path="/portfolio" element={<ProtectedRoute element={<Portfolio />} />} />
          <Route path="/about" element={<ProtectedRoute element={<About />} />} />
          {/* Agrega más rutas según sea necesario */}
        </Routes>
      </Router>
    </AuthProvider>
  );
};

export default App;


