import React, { useEffect, useState } from 'react';
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/solid';
import { ClipboardIcon, ArrowDownCircleIcon } from '@heroicons/react/24/outline';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const ServerInfo = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [downloadError, setDownloadError] = useState(null);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/serverinfo`)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        setData(data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, []);

  const handleCopy = () => {
    navigator.clipboard.writeText(data.publicIp);
  };

  const handleDownloadLogs = () => {
    const day = String(selectedDate.getDate()).padStart(2, '0');
    const month = String(selectedDate.getMonth() + 1).padStart(2, '0'); // Months are 0-based in JS
    const year = selectedDate.getFullYear();
    const dateStr = `${day}${month}${year}`;

    fetch(`${process.env.REACT_APP_API_URL}/getlogs?date=${dateStr}`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.blob();
      })
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = `${dateStr}_logs.zip`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch(error => {
        setDownloadError(error.message);
      });
  };

  if (loading) {
    return <div className="text-center text-white">Loading...</div>;
  }

  if (error) {
    return <div className="text-center text-red-500">Error: {error.message}</div>;
  }

  const { publicIp, memoryStatus, diskStatus, hardwareInfo, processesRunning } = data;

  const totalMemoryMatch = memoryStatus.match(/Total Visible Memory: (\d+) MB/);
  const freeMemoryMatch = memoryStatus.match(/Free Physical Memory: (\d+) MB/);
  const totalMemory = totalMemoryMatch ? parseInt(totalMemoryMatch[1], 10) : 0;
  const freeMemory = freeMemoryMatch ? parseInt(freeMemoryMatch[1], 10) : 0;
  const usedMemory = totalMemory - freeMemory;
  const usedMemoryPercentage = totalMemory ? ((usedMemory / totalMemory) * 100).toFixed(2) : 0;

  const renderProcessStatus = (processName, isRunning) => {
    let displayName;
    switch (processName) {
      case 'sqlservr':
        displayName = 'Database Engine';
        break;
      case 'AstraPACS':
        displayName = 'Picture Archiving and Communication System';
        break;
      case 'AstraAPI':
        displayName = 'Application Programming Interface';
        break;
      default:
        displayName = processName;
    }
    return (
      <div className="flex items-center space-x-2" key={processName}>
        {isRunning ? (
          <CheckCircleIcon className="w-6 h-6 text-green-500" />
        ) : (
          <XCircleIcon className="w-6 h-6 text-red-500" />
        )}
        <span>{displayName}</span>
      </div>
    );
  };

  return (
    <div className="container mx-auto p-6">
      <div className="bg-gray-800 shadow rounded-lg p-6 text-center">
        <h1 className="text-3xl text-white font-bold mb-4">Public Internet Protocol Address</h1>
        <div className="flex justify-center items-center space-x-2">
          <p className="text-2xl text-white/60">{publicIp}</p>
          <button onClick={handleCopy} className="text-blue-500 hover:text-blue-700">
            <ClipboardIcon className="w-6 h-6" />
          </button>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-6">
        <div className="bg-gray-800 shadow rounded-lg p-6">
          <h2 className="text-xl text-white font-semibold mb-2">Memory Status</h2>
          <pre className="text-white/60 whitespace-pre-wrap">{memoryStatus}</pre>
          <p className="text-white/60">Memory Used: {usedMemoryPercentage}%</p>
        </div>

        <div className="bg-gray-800 shadow rounded-lg p-6">
          <h2 className="text-xl text-white font-semibold mb-2">Disk Status</h2>
          <pre className="text-white/60 whitespace-pre-wrap">{diskStatus}</pre>
        </div>

        <div className="bg-gray-800 shadow rounded-lg p-6">
          <h2 className="text-xl text-white font-semibold mb-2">Hardware Info</h2>
          <pre className="text-white/60 whitespace-pre-wrap">{hardwareInfo}</pre>
        </div>

        <div className="bg-gray-800 shadow rounded-lg p-6">
          <h2 className="text-xl font-semibold text-white mb-2">Processes Running</h2>
          <div className="space-y-2 text-white/60">
            {Object.entries(processesRunning).map(([processName, isRunning]) => (
              <div key={processName}>{renderProcessStatus(processName, isRunning)}</div>
            ))}
          </div>
        </div>
      </div>

      <div className="bg-gray-800 shadow rounded-lg p-6 mt-6">
        <h2 className="text-xl text-white font-semibold mb-2">Logs:</h2>
        <div className="flex items-center space-x-2">
          <DatePicker
            selected={selectedDate}
            onChange={date => setSelectedDate(date)}
            dateFormat="dd/MM/yyyy"
            className="p-2 rounded bg-gray-900 text-white"
          />
          <button
            onClick={handleDownloadLogs}
            className=" text-blue-500 hover:text-blue-700"
          >
            <ArrowDownCircleIcon className="w-6 h-6" />
          </button>
        </div>
        {downloadError && <p className="text-red-500 mt-2">{downloadError}</p>}
      </div>
    </div>
  );
};

export default ServerInfo;
