import React, { useEffect, useRef } from 'react';
import vtkVolume from '@kitware/vtk.js/Rendering/Core/Volume';
import vtkVolumeMapper from '@kitware/vtk.js/Rendering/Core/VolumeMapper';
import vtkImageData from '@kitware/vtk.js/Common/DataModel/ImageData';
import vtkRenderer from '@kitware/vtk.js/Rendering/Core/Renderer';
import vtkRenderWindow from '@kitware/vtk.js/Rendering/Core/RenderWindow';
import vtkRenderWindowInteractor from '@kitware/vtk.js/Rendering/Core/RenderWindowInteractor';
import vtkOpenGLRenderWindow from '@kitware/vtk.js/Rendering/OpenGL/RenderWindow';
import vtkDataArray from '@kitware/vtk.js/Common/Core/DataArray';
import vtkVolumeController from '@kitware/vtk.js/Interaction/UI/VolumeController';

import '@kitware/vtk.js/Rendering/Profiles/Volume';
import '@kitware/vtk.js/Rendering/Profiles/All';

const StudyModel3D = ({ width, height, depth, numComps, dataType, base64Data }) => {
  console.log("Width:", width, "Height:", height, "Depth:", depth, "NumComps:", numComps, "DataType:", dataType);
  const containerRef = useRef(null);

  useEffect(() => {
    if (!containerRef.current) return;

    let renderWindow, renderer, openGLRenderWindow, interactor;
    let volume, mapper, imageData, dataArray;

    try {
      // Initialize VTK.js rendering objects
      renderWindow = vtkRenderWindow.newInstance();
      renderer = vtkRenderer.newInstance();
      openGLRenderWindow = vtkOpenGLRenderWindow.newInstance();
      interactor = vtkRenderWindowInteractor.newInstance();

      // Set up the OpenGL render window
      openGLRenderWindow.setContainer(containerRef.current);
      renderWindow.addView(openGLRenderWindow);
      renderWindow.addRenderer(renderer);

      // Set up the interactor
      interactor.setView(openGLRenderWindow);
      interactor.initialize();
      interactor.bindEvents(containerRef.current);

      // Verify and log base64 data
      if (!base64Data) {
        throw new Error("base64Data is null or undefined.");
      }
      console.log("Base64 Data Length:", base64Data.length);

      // Convert base64 to binary data
      const binaryString = atob(base64Data);
      const binaryData = new Uint8Array(binaryString.length);
      for (let i = 0; i < binaryString.length; i++) {
        binaryData[i] = binaryString.charCodeAt(i);
      }

      // Verify the length of binary data
      console.log("Binary Data Length:", binaryData.length);
      console.log("First 100 bytes of binary data:", binaryData.slice(0, 100));
      const expectedLength = width * height * depth * numComps * (dataType === 'unsigned_short' ? 2 : 1);
      if (binaryData.length  !== expectedLength) {
        throw new Error(`Binary data length (${binaryData.length}) does not match the expected length (${expectedLength}).`);
      }

      // Define the data type for vtkDataArray
      let vtkDataType;
      if (dataType === "unsigned_char") {
        vtkDataType = Uint8Array;
      } else if (dataType === "unsigned_short") {
        vtkDataType = Uint16Array;
      } else {
        throw new Error(`Unsupported data type: ${dataType}`);
      }

      // Create the image data
      dataArray = vtkDataArray.newInstance({
        name: 'Scalars',
        numberOfComponents: numComps,
        values: new vtkDataType(binaryData.buffer),
      });

      imageData = vtkImageData.newInstance();
      imageData.setDimensions([width, height, depth]);
      imageData.setSpacing([1, 1, 1]);
      imageData.getPointData().setScalars(dataArray);

      console.log("Image Data Initialized:", imageData);

      // Create the mapper and volume
      mapper = vtkVolumeMapper.newInstance();
      mapper.setSampleDistance(0.7);
      mapper.setMaximumSamplesPerRay(2000);
      mapper.setInputData(imageData);

      volume = vtkVolume.newInstance();
      volume.setMapper(mapper);

      console.log("Mapper and Volume Initialized:", mapper, volume);

      // Add the volume to the renderer
      renderer.addVolume(volume);
      renderer.resetCamera();

      // Ensure everything is initialized before rendering
      if (!renderWindow || !renderer || !openGLRenderWindow || !volume || !mapper || !imageData) {
        throw new Error("One or more VTK.js components are not initialized properly.");
      }

      console.log("Rendering...");
      renderWindow.render();

      // Add the volume controller
      const controllerWidget = vtkVolumeController.newInstance({ size: [400, 150] });
      controllerWidget.setContainer(containerRef.current);
      controllerWidget.setupContent(renderWindow, volume, true);
    } catch (error) {
      console.error('Error initializing VTK.js components:', error);
    }

    // Clean up VTK.js objects on component unmount
    return () => {
      if (interactor) interactor.unbindEvents();
      if (renderWindow) renderWindow.removeRenderer(renderer);
      if (renderWindow) renderWindow.removeView(openGLRenderWindow);
      if (openGLRenderWindow) openGLRenderWindow.setContainer(null);
      if (renderer) renderer.delete();
      if (renderWindow) renderWindow.delete();
      if (openGLRenderWindow) openGLRenderWindow.delete();
      if (interactor) interactor.delete();
      if (volume) volume.delete();
      if (mapper) mapper.delete();
      if (imageData) imageData.delete();
      if (dataArray) dataArray.delete();
    };
  }, [width, height, depth, numComps, dataType, base64Data]);

  return <div ref={containerRef} className="vtk-container" style={{ width: '100%', height: '100%' }} />;
};

export default StudyModel3D;
