import React, { useState, useEffect, useRef, useCallback } from 'react';
import { CreateWebWorkerMLCEngine } from '@mlc-ai/web-llm';
import { ArrowUpCircleIcon, UserCircleIcon } from '@heroicons/react/24/outline';

export default function Chatbot() {
    const [loading, setLoading] = useState(true);
    const [progress, setProgress] = useState(0);
    const [inputValue, setInputValue] = useState('');
    const containerRef = useRef(null);
    const inputRef = useRef(null);
    const messagesRef = useRef([]);
    const engineRef = useRef(null);

    const SELECTED_MODEL = 'gemma-2b-it-q4f16_1-MLC-1k';

    useEffect(() => {
        const initChatbot = async () => {
            try {
                const engine = await CreateWebWorkerMLCEngine(
                    new Worker(`${process.env.PUBLIC_URL}/worker.js`, { type: 'module' }),
                    SELECTED_MODEL,
                    {
                        initProgressCallback: (info) => {
                            setProgress(Math.floor(info.progress * 100));
                            if (info.progress === 1) {
                                setLoading(false);
                                if (messagesRef.current.length === 0) {
                                    // addMessage("Hello! How can I assist you?", 'bot');
                                    inputRef.current.focus();
                                }
                            }
                        }
                    }
                );
                engineRef.current = engine;
            } catch (error) {
                console.error("Error initializing the chatbot:", error);
                setLoading(false);
            }
        };

        initChatbot();
    }, []); // Se asegura de que useEffect se ejecuta una sola vez.

    const addMessage = useCallback((text, sender, incremental = false) => {
        const messageContainer = document.createElement('li');
        messageContainer.className = `message ${sender} flex flex-col my-3 p-3 max-w-md rounded-lg shadow-lg`;

        const span = document.createElement('span');
        span.className = 'w-9 h-9 flex justify-center items-center rounded-full text-sm font-medium';
        if (sender === 'bot') {
            const img = document.createElement('img');
            img.src = '/favicon.ico';
            img.alt = 'Bot';
            img.className = 'w-6 h-6';
            span.className += ' bg-gray-500 text-white';
            span.appendChild(img);
        } else {
            span.className += ' bg-blue-500 text-white';
            const icon = document.createElement('span');
            icon.innerHTML = '<svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5.121 17.804A12.056 12.056 0 0112 15c3.1 0 5.937 1.148 8.121 2.804M12 11c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4z"></path></svg>';
            span.appendChild(icon);
        }

        const p = document.createElement('p');
        p.textContent = text;
        p.className = 'rounded p-3 mt-2 bg-white';

        if (sender === 'bot') {
            messageContainer.classList.add('self-start', 'bg-gray-100');
        } else {
            messageContainer.classList.add('self-end', 'bg-blue-100');
        }

        messageContainer.appendChild(span);
        messageContainer.appendChild(p);

        const messagesList = containerRef.current.querySelector('ul');
        messagesList.appendChild(messageContainer);

        if (incremental) {
            return p;
        }

        containerRef.current.scrollTop = containerRef.current.scrollHeight - containerRef.current.clientHeight;
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();
        const messageText = inputValue.trim();

        if (messageText !== '') {
            setInputValue('');
            addMessage(messageText, 'user');
        }

        const userMessage = {
            role: 'user',
            content: messageText
        };

        messagesRef.current.push(userMessage);

        try {
            const response = await engineRef.current.chat.completions.create({
                messages: messagesRef.current,
                stream: true
            });

            let reply = '';
            const p = addMessage('', 'bot', true);

            for await (const chunk of response) {
                const content = chunk.choices[0].delta.content;
                if (content) {
                    reply += content;
                    p.textContent = reply;
                }
                containerRef.current.scrollTop = containerRef.current.scrollHeight - containerRef.current.clientHeight;
            }

            messagesRef.current.push({
                role: 'assistant',
                content: reply
            });
        } catch (error) {
            console.error("Error while fetching chat completion:", error);
        }
    };

    return (
        <div className="w-full flex flex-col items-center mt-20">
            <main ref={containerRef} className="w-11/12 md:w-1/2 lg:w-1/3 bg-gray-900 border border-gray-700 rounded-lg shadow-lg p-4 mb-4 overflow-y-auto" style={{ maxHeight: '70vh' }}>
                <ul className="flex flex-col list-none p-0">
                    {loading && (
                        <li className="flex flex-col items-center justify-center h-full">
                            <div className="w-16 h-16 border-4 border-t-sky-300 border-gray-200 rounded-full animate-spin"></div>
                            <h4 className="text-gray-200 mt-4">Loading...</h4>
                            <h5 className="text-gray-400 text-sm mt-2">GPT Model is being downloaded, this might last some time.</h5>
                            <h6 className="text-gray-500 text-xs mb-2">Compatible with Microsoft Edge or Google Chrome</h6>
                            <small className="text-gray-500">{progress}%</small>
                        </li>
                    )}
                </ul>
            </main>
            <form className="flex w-11/12 md:w-1/2 lg:w-1/3 mb-4" onSubmit={handleSubmit}>
                <input
                    ref={inputRef}
                    type="text"
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    placeholder="Write your message here..."
                    className="flex-grow border border-gray-600 rounded-full p-2 mr-2 pl-4 text-gray-300 bg-gray-800 placeholder-gray-500"
                />
                <button
                    type="submit"
                    className="bg-sky-300 text-white rounded-full p-2 disabled:opacity-50"
                    disabled={loading}
                >
                    <ArrowUpCircleIcon className="w-6 h-6" />
                </button>
            </form>
        </div>
    );
}
