import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';

export default function LoginForm() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();
    const { login } = useAuth();

    const handleLogin = async (e) => {
        e.preventDefault();

        const loginRequest = {
            UserName: username,
            Password: password
        };

        const response = await fetch(`${process.env.REACT_APP_API_URL}/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(loginRequest)
        });

        if (response.ok) {
            const user = await response.json();
            console.log('Login successful', user);
            login(user.Role);
            navigate('/home');
        } else {
            console.log('Login failed');
            setErrorMessage('Login failed. Please check your username and password.');
        }
    };

    return (
        <div className="relative w-full max-w-lg">
            <div className="relative -mb-px h-px w-full bg-gradient-to-r from-transparent via-sky-300 to-transparent"></div>
            <div className="mx-5 border dark:border-b-white/50 dark:border-t-white/50 border-b-white/20 sm:border-t-white/20  rounded-lg border-white/20 border-l-white/20 border-r-white/20 sm:shadow-sm lg:rounded-xl lg:shadow-none">
                <div className="flex flex-col p-6">
                    <p className="mt-1.5 text-sm font-medium text-white/50">Welcome, enter your credentials to continue.</p>
                </div>
                <div className="p-6 pt-0">
                    <form onSubmit={handleLogin}>
                        {errorMessage && <p className="text-red-500 mb-4">{errorMessage}</p>}
                        <div>
                            <div>
                                <div className="group relative rounded-lg border focus-within:border-sky-200 px-3 pb-1.5 pt-2.5 duration-200 focus-within:ring focus-within:ring-sky-300/30">
                                    <div className="flex justify-between">
                                        <label className="text-xs font-medium text-muted-foreground group-focus-within:text-white text-gray-400">Username</label>
                                    </div>
                                    <input
                                        type="text"
                                        name="username"
                                        autoComplete="off"
                                        className="block w-full border-0 bg-transparent p-0 text-sm file:my-1 file:rounded-full file:border-0 file:bg-accent file:px-4 file:py-2 file:font-medium placeholder:text-muted-foreground/90 focus:outline-none focus:ring-0 sm:leading-7 text-foreground text-white"
                                        value={username}
                                        onChange={(e) => setUsername(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="mt-4">
                            <div>
                                <div className="group relative rounded-lg border focus-within:border-sky-200 px-3 pb-1.5 pt-2.5 duration-200 focus-within:ring focus-within:ring-sky-300/30">
                                    <div className="flex justify-between">
                                        <label className="text-xs font-medium text-muted-foreground group-focus-within:text-white text-gray-400">Password</label>
                                    </div>
                                    <div className="flex items-center">
                                        <input
                                            type="password"
                                            name="password"
                                            className="block w-full border-0 text-white bg-transparent p-0 text-sm file:my-1 placeholder:text-muted-foreground/90 focus:outline-none focus:ring-0 focus:ring-teal-500 sm:leading-7 text-foreground"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mt-4 flex items-center justify-end gap-x-2">
                            <button className="font-semibold hover:bg-[#05141f] hover:text-white hover:ring hover:ring-white transition duration-300 inline-flex items-center justify-center rounded-md text-sm focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-white text-black h-10 px-4 py-2" type="submit">Login</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}
