import Layout from "../Layouts/Layouts"
import Chatbot from "../Components/Chatbot.jsx"


export default function ChatPage() { 
    return(
    <Layout>    
        <Chatbot />
    </Layout> 
);
}