import React, { useState, useEffect, useMemo } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import './datagrid.css';
import Modal from '../Components/Modal';
import Send2WorklistForm from '../Windows/Send2Worklist'; // Asegúrate de la ruta correcta
import { PaperAirplaneIcon, XCircleIcon, PencilIcon } from '@heroicons/react/24/solid'; // Asegúrate de tener Heroicons instalado
import ConfirmationModal from './ConfirmationModal';

const PatientsDatagrid = ({ apiUrl }) => {
    const [rowData, setRowData] = useState([]);
    const [error, setError] = useState(null);
    const [isSendWorklistPatientModalOpen, setIsSendWorklistPatientModalOpen] = useState(false);
    const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
    const [selectedPatient, setSelectedPatient] = useState(null);
    const [selectedPatientId, setSelectedPatientId] = useState(null);

    const handleSendWorklistPatientOpenModal = (patient) => {
        setSelectedPatient(patient);
        setIsSendWorklistPatientModalOpen(true);
    };

    const handleDeletePatientEntry = async (patientId) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/deletepatient?patientId=${patientId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            console.log('Entry deleted successfully');
            // Optionally, refresh the grid data here
            setRowData(prevData => prevData.filter(patient => patient.patientUid !== patientId));
        } catch (error) {
            console.error('Failed to delete entry:', error);
        }
    };

    const handleCloseModal = () => {
        setIsSendWorklistPatientModalOpen(false);
    };

    const openConfirmationModal = (patientId) => {
        setSelectedPatientId(patientId);
        setIsConfirmationModalOpen(true);
    };

    const closeConfirmationModal = () => {
        setIsConfirmationModalOpen(false);
        setSelectedPatientId(null);
    };

    const confirmDelete = async () => {
        if (selectedPatientId) {
            await handleDeletePatientEntry(selectedPatientId);
            closeConfirmationModal();
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(apiUrl);
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                const data = await response.json();
                const transformedData = data.map(item => ({
                    patientUid: item.patientUid,
                    patId: item.identifier,
                    surname: item.surName,
                    forename: item.foreName,
                    dob: item.birthDate,
                    sex: item.sex,
                    ethnicity: item.ethnicGroup,
                    email: item.email,
                    address: item.address,
                    city: item.city,
                    state: item.state,
                    postalcode: item.postalCode,
                    country: item.country,
                    phone: item.phone
                }));
                setRowData(transformedData);
            } catch (error) {
                console.error('Error fetching data:', error);
                setError(error.message);
            }
        };

        fetchData();
    }, [apiUrl]);

    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            editable: false,
            filter: true,
        };
    }, []);

    const SendToWorklistButton = (p) => {
        return (
            <button
                onClick={() => handleSendWorklistPatientOpenModal(p.data)}
                className="action-button text-blue-500 hover:text-blue-700"
            >
                <PaperAirplaneIcon className="h-5 w-5" />
            </button>
        );
    };

    const DeleteButton = (p) => {
        return (
                  <button
                onClick={() => openConfirmationModal(p.data.patientUid)}
                className="action-button text-red-500 hover:text-red-700"
            >
                <XCircleIcon className="h-5 w-5" />
            </button>

          
        );
    };

    const colDefs = useMemo(() => [
        { field: "sendToWorklist", headerName: "", cellRenderer: SendToWorklistButton, flex: 0.5 },
        // { field: "patientUid", headerName: "Patient UID" },
        { field: "patId", headerName: "Patient Identifier" },
        { field: "surname", headerName: "Surname", flex: 1.5 },
        { field: "forename", headerName: "Forename" },
        { field: "dob", headerName: "Date of Birth" },
        { field: "sex", headerName: "Sex" },
        { field: "ethnicity", headerName: "Ethnicity" },
        { field: "email", headerName: "Email", flex: 1.5 },
        { field: "address", headerName: "Address" },
        { field: "city", headerName: "City" },
        { field: "state", headerName: "State" },
        { field: "postalcode", headerName: "Postal Code" },
        { field: "country", headerName: "Country" },
        { field: "phone", headerName: "Phone" },
        { field: "delete", headerName: "", cellRenderer: DeleteButton, flex: 0.5 }
    ], []);

    return (
        <>
            <div className='ag-theme-quartz-dark h-full flex-grow'>
                <AgGridReact
                    rowData={rowData}
                    columnDefs={colDefs}
                    pagination={true}
                    rowSelection='multiple'
                    paginationPageSize={20}
                    defaultColDef={defaultColDef}
                />
            </div>

            <Modal isOpen={isSendWorklistPatientModalOpen} onClose={handleCloseModal}>
                <Send2WorklistForm onSubmit={handleCloseModal} patientData={selectedPatient} />
            </Modal>

            <ConfirmationModal
                isOpen={isConfirmationModalOpen}
                onClose={closeConfirmationModal}
                onConfirm={confirmDelete}
                title="Confirm Deletion"
                message={`Do you want to delete this patient ${selectedPatientId}?`}
            />
        </>
    );
};

export default PatientsDatagrid;
