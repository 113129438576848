import React, { useState } from 'react';
import Select from 'react-select';

const RegisterClientForm = ({ onSubmit, onClose }) => {
  const [formData, setFormData] = useState({
    aeTitle: '',
    ipAddress: '',
    description: '',
    allowedModality: []
  });

  const dicomModalities = [
    { value: "ANN", label: "ANN - Annotation" },
    { value: "AR", label: "AR - Autorefraction" },
    { value: "ASMT", label: "ASMT - Content Assessment Results" },
    { value: "AU", label: "AU - Audio" },
    { value: "BDUS", label: "BDUS - Bone Densitometry (ultrasound)" },
    { value: "BI", label: "BI - Biomagnetic imaging" },
    { value: "BMD", label: "BMD - Bone Densitometry (X-Ray)" },
    { value: "CFM", label: "CFM - Confocal Microscopy" },
    { value: "CR", label: "CR - Computed Radiography" },
    { value: "CT", label: "CT - Computed Tomography" },
    { value: "CTPROTOCOL", label: "CTPROTOCOL - CT Protocol (Performed)" },
    { value: "DMS", label: "DMS - Dermoscopy" },
    { value: "DG", label: "DG - Diaphanography" },
    { value: "DOC", label: "DOC - Document" },
    { value: "DX", label: "DX - Digital Radiography" },
    { value: "ECG", label: "ECG - Electrocardiography" },
    { value: "EEG", label: "EEG - Electroencephalography" },
    { value: "EMG", label: "EMG - Electromyography" },
    { value: "EOG", label: "EOG - Electrooculography" },
    { value: "EPS", label: "EPS - Cardiac Electrophysiology" },
    { value: "ES", label: "ES - Endoscopy" },
    { value: "FID", label: "FID - Fiducials" },
    { value: "GM", label: "GM - General Microscopy" },
    { value: "HC", label: "HC - Hard Copy" },
    { value: "HD", label: "HD - Hemodynamic Waveform" },
    { value: "IO", label: "IO - Intra-Oral Radiography" },
    { value: "IOL", label: "IOL - Intraocular Lens Data" },
    { value: "IVOCT", label: "IVOCT - Intravascular Optical Coherence Tomography" },
    { value: "IVUS", label: "IVUS - Intravascular Ultrasound" },
    { value: "KER", label: "KER - Keratometry" },
    { value: "KO", label: "KO - Key Object Selection" },
    { value: "LEN", label: "LEN - Lensometry" },
    { value: "LS", label: "LS - Laser surface scan" },
    { value: "MG", label: "MG - Mammography" },
    { value: "MR", label: "MR - Magnetic Resonance" },
    { value: "M3D", label: "M3D - Model for 3D Manufacturing" },
    { value: "NM", label: "NM - Nuclear Medicine" },
    { value: "OAM", label: "OAM - Ophthalmic Axial Measurements" },
    { value: "OCT", label: "OCT - Optical Coherence Tomography (non-Ophthalmic)" },
    { value: "OP", label: "OP - Ophthalmic Photography" },
    { value: "OPM", label: "OPM - Ophthalmic Mapping" },
    { value: "OPT", label: "OPT - Ophthalmic Tomography" },
    { value: "OPTBSV", label: "OPTBSV - Ophthalmic Tomography B-scan Volume Analysis" },
    { value: "OPTENF", label: "OPTENF - Ophthalmic Tomography En Face" },
    { value: "OPV", label: "OPV - Ophthalmic Visual Field" },
    { value: "OP", label: "OP - Ophthalmic Photography" },
    { value: "OSS", label: "OSS - Optical Surface Scan" },
    { value: "OT", label: "OT - Other" },
    { value: "PA", label: "PA - Photoacoustic" },
    { value: "PLAN", label: "PLAN - Plan" },
    { value: "POS", label: "POS - Position Sensor" },
    { value: "PR", label: "PR - Presentation State" },
    { value: "PT", label: "PT - Positron emission tomography (PET)" },
    { value: "PX", label: "PX - Panoramic X-Ray" },
    { value: "REG", label: "REG - Registration" },
    { value: "SC", label: "SC - Secondary Capture" },
    { value: "RESP", label: "RESP - Respiratory Waveform" },
    { value: "RF", label: "RF - Radio Fluoroscopy" },
    { value: "RG", label: "RG - Radiographic imaging (conventional film/screen)" },
    { value: "RTDOSE", label: "RTDOSE - Radiotherapy Dose" },
    { value: "RTIMAGE", label: "RTIMAGE - Radiotherapy Image" },
    { value: "RTINTENT", label: "RTINTENT - Radiotherapy Intent" },
    { value: "RTPLAN", label: "RTPLAN - Radiotherapy Plan" },
    { value: "RTRAD", label: "RTRAD - RT Radiation" },
    { value: "RTRECORD", label: "RTRECORD - RT Treatment Record" },
    { value: "RTSEGANN", label: "RTSEGANN - Radiotherapy Segment Annotation" },
    { value: "RTSTRUCT", label: "RTSTRUCT - Radiotherapy Structure Set" },
    { value: "RWV", label: "RWV - Real World Value Map" },
    { value: "SEG", label: "SEG - Segmentation" },
    { value: "SM", label: "SM - Slide Microscopy" },
    { value: "SMR", label: "SMR - Stereometric Relationship" },
    { value: "SR", label: "SR - SR Document" },
    { value: "SRF", label: "SRF - Subjective Refraction" },
    { value: "STAIN", label: "STAIN - Automated Slide Stainer" },
    { value: "TEXTUREMAP", label: "TEXTUREMAP - Texture Map" },
    { value: "TG", label: "TG - Thermography" },
    { value: "US", label: "US - Ultrasound" },
    { value: "VA", label: "VA - Visual Acuity" },
    { value: "XA", label: "XA - X-Ray Angiography" },
    { value: "XAPROTOCOL", label: "XAPROTOCOL - XA Protocol (Performed)" },
    { value: "XC", label: "XC - External-camera Photography" }
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleModalityChange = (selectedOptions) => {
    setFormData({
      ...formData,
      allowedModality: selectedOptions.map(option => option.value),
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const aeData = {
      aeTitle: formData.aeTitle,
      ipAddress: formData.ipAddress,
      description: formData.description
    };

    console.log('SCU Data:', aeData);

    try {
      const aeResponse = await fetch(`${process.env.REACT_APP_API_URL}/addcallingae`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(aeData),
      });

      if (!aeResponse.ok) {
        throw new Error(`HTTP error! status: ${aeResponse.status}`);
      }

      const aeResult = await aeResponse.json();
      console.log('Success:', aeResult);

      for (const modality of formData.allowedModality) {
        const modalityData = {
          insertedDate: new Date().toISOString(),
          callingAET: formData.aeTitle,
          allowedModality: modality
        };

        const modalityResponse = await fetch(`${process.env.REACT_APP_API_URL}/addmodality`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(modalityData),
        });

        if (!modalityResponse.ok) {
          throw new Error(`HTTP error! status: ${modalityResponse.status}`);
        }

        const modalityResult = await modalityResponse.json();
        console.log('Success:', modalityResult);
      }

      onSubmit(aeResult);
      onClose();

    } catch (error) {
      console.error('Fetch error:', error.message);

      if (error.message === 'Failed to fetch') {
        console.error('Network error - please check if the server is running and CORS is configured correctly');
      }
    }
  };

  return (
    <form className="w-full max-w-lg mx-auto grid grid-cols-2 gap-2 max-h-screen" onSubmit={handleSubmit}>
      <div className="col-span-2">
        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="aeTitle">
          AE Title
        </label>
        <input
          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
          id="aeTitle"
          type="text"
          name="aeTitle"
          value={formData.aeTitle}
          onChange={handleChange}
          placeholder="AE Title"
          required
        />
      </div>
      <div className="col-span-2">
        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="ipAddress">
          IP Address
        </label>
        <input
          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
          id="ipAddress"
          type="text"
          name="ipAddress"
          value={formData.ipAddress}
          onChange={handleChange}
          placeholder="IP Address"
          required
        />
      </div>
      <div className="col-span-2">
        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="description">
          Description
        </label>
        <input
          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
          id="description"
          type="text"
          name="description"
          value={formData.description}
          onChange={handleChange}
          placeholder="Description"
          required
        />
      </div>
      <div className="col-span-2">
        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="allowedModality">
          Allowed Modality
        </label>
        <Select
          isMulti
          name="allowedModality"
          options={dicomModalities}
          className="basic-multi-select"
          classNamePrefix="select"
          onChange={handleModalityChange}
        />
      </div>
      <div className="col-span-2 mt-2 flex justify-center">
        <button
          type="submit"
          className="bg-indigo-500 text-white px-4 py-2 rounded-md shadow-sm hover:bg-indigo-700"
        >
          Register
        </button>
      </div>
    </form>
  );
};

export default RegisterClientForm;
