import React, { useState } from 'react';

const RetrieveWorklistForm = ({ onRetrieve }) => {
  const [formData, setFormData] = useState({
    ip: '',
    port: '',
    SCUAETitle: '',
    SCPAETitle: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const queryParams = new URLSearchParams(formData).toString();
      const response = await fetch(`${process.env.REACT_APP_API_URL}/retrieveworklist?${queryParams}`, {
        method: 'GET',
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      console.log('Success:', result);
      if (typeof onRetrieve === 'function') {
        onRetrieve(result);
      }
    } catch (error) {
      console.error('Fetch error:', error.message);
      if (error.message === 'Failed to fetch') {
        console.error('Network error - please check if the server is running and CORS is configured correctly');
      }
    }
  };

  return (
    <form className="flex flex-col space-y-4" onSubmit={handleSubmit}>
      <div>
        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="ip">
          IP Address
        </label>
        <input
          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
          id="ip"
          type="text"
          name="ip"
          value={formData.ip}
          onChange={handleChange}
          placeholder="127.0.0.1"
          required
        />
      </div>
      <div>
        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="port">
          Port
        </label>
        <input
          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
          id="port"
          type="text"
          name="port"
          value={formData.port}
          onChange={handleChange}
          placeholder="1011"
          required
        />
      </div>
      <div>
        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="SCUAETitle">
          SCUAE Title
        </label>
        <input
          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
          id="SCUAETitle"
          type="text"
          name="SCUAETitle"
          value={formData.SCUAETitle}
          onChange={handleChange}
          placeholder="SCUAE Title"
          required
        />
      </div>
      <div>
        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="SCPAETitle">
          SCPAETitle
        </label>
        <input
          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
          id="SCPAETitle"
          type="text"
          name="SCPAETitle"
          value={formData.SCPAETitle}
          onChange={handleChange}
          placeholder="RS_ASTRA"
          required
        />
      </div>
      <div className="flex justify-center">
        <button type="submit" className="bg-indigo-500 text-white px-4 py-2 rounded-md shadow-sm hover:bg-indigo-700">
          Retrieve Worklist
        </button>
      </div>
    </form>
  );
};

export default RetrieveWorklistForm;
