import React, { useMemo, useState, useEffect } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import './datagrid.css';
import { XCircleIcon } from '@heroicons/react/24/solid';
import ConfirmationModal from './ConfirmationModal';

const Actions = (p) => {
    return (
        <>
            <button
                onClick={() => p.onDelete(p.data.callingAETUid, p.data.aeTitle)}
                className="action-button text-red-500 hover:text-red-700"
            >
                <XCircleIcon className="h-5 w-5" />
            </button>
        </>
    );
}

const handleDeleteCallingEntry = async (callingAETUid, onEntryDeleted) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/deletecallingae?callingAETUid=${callingAETUid}`, {
            method: 'POST', // Changed from DELETE to POST
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        console.log('Entry deleted successfully');
        if (onEntryDeleted) {
            onEntryDeleted();
        }
    } catch (error) {
        console.error('Failed to delete entry:', error);
    }
};


const ClientsDatagrid = ({ apiUrl }) => {
    const [rowData, setRowData] = useState([]);
    const [error, setError] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedAETUid, setSelectedAETUid] = useState(null);
    const [selectedAETitle, setSelectedAETitle] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(apiUrl);
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                const data = await response.json();
                const transformedData = data.map(item => ({
                    callingAETUid: item.callingAETUid,
                    aeTitle: item.aeTitle,
                    ipAddress: item.ipAddress,
                    allowedModality: item.allowedModality,
                    description: item.description
                }));
                setRowData(transformedData);
            } catch (error) {
                console.error('Error fetching data:', error);
                setError(error.message);
            }
        };

        fetchData();
    }, [apiUrl]);

    const openModal = (callingAETUid, aeTitle) => {
        setSelectedAETUid(callingAETUid);
        setSelectedAETitle(aeTitle);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedAETUid(null);
        setSelectedAETitle(null);
    };

    const confirmDelete = async () => {
        await handleDeleteCallingEntry(selectedAETUid);
        closeModal();
        // Optionally, refresh the grid data here
    };

    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            editable: false,
            filter: true,
        };
    }, []);

    const [colDefs, setColDefs] = useState([
        { field: "callingAETUid", headerName: "SCU AETitle UID" },
        { field: "aeTitle", headerName: "AE Title" },
        { field: "ipAddress", headerName: "IP Address" },
        { field: "allowedModality", headerName: "Modality" },
        { field: "description", headerName: "Description" },
        {
            field: "actions", headerName: "Actions", cellRenderer: Actions,
            cellRendererParams: {
                onDelete: openModal
            }
        },
    ]);

    return (
        <div className='ag-theme-quartz-dark h-full flex-grow'>
            <AgGridReact
                rowData={rowData}
                columnDefs={colDefs}
                pagination={true}
                rowSelection='multiple'
                paginationPageSize={20}
                defaultColDef={defaultColDef}
                frameworkComponents={{
                    actionsRenderer: Actions
                }}
            />
            <ConfirmationModal
                isOpen={isModalOpen}
                onClose={closeModal}
                onConfirm={confirmDelete}
                title="Confirm Deletion"
                message={`Do you want to delete this client ${selectedAETitle}?`}
            />
        </div>
    );
}

export default ClientsDatagrid;
