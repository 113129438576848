import React, { useState } from 'react';

const TagsWindow = ({ tags = [] }) => {
  const [searchTerm, setSearchTerm] = useState("");

  const filteredTags = tags.filter(tag =>
    tag.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <div className="p-5 mt-5">
      <h2 className="text-xl text-black font-bold mb-4">DICOM Tags</h2>
      <input
        type="text"
        placeholder="Search tags..."
        value={searchTerm}
        onChange={handleSearch}
        className="mb-4 p-2 border text-gray-800 bg-transparent border-gray-300 rounded w-full"
      />
      <ul className="list-disc pl-5 text-gray-800 max-h-96 overflow-auto">
        {filteredTags.length > 0 ? (
          filteredTags.map((tag, index) => {
            const [tagName, tagValue] = tag.split(':');
            return (
              <li key={index} className="mb-2 text-base break-words">
                <span className="font-bold">{tagName}:</span>
                <span>{tagValue}</span>
              </li>
            );
          })
        ) : (
          <li>No tags available</li>
        )}
      </ul>
    </div>
  );
};

export default TagsWindow;
