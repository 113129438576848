import React, { useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'tailwindcss/tailwind.css';

const localizer = momentLocalizer(moment);

const MyCalendar = () => {
  const [appointments, setAppointments] = useState([]);
  const [newAppointment, setNewAppointment] = useState({ date: '', time: '', description: '' });

  const handleAddAppointment = () => {
    const dateTime = new Date(newAppointment.date + 'T' + newAppointment.time);
    const newApp = {
      title: newAppointment.description,
      start: dateTime,
      end: new Date(dateTime.getTime() + 30 * 60 * 1000), // Assuming the appointment lasts 30 minutes
    };
    setAppointments([...appointments, newApp]);
    setNewAppointment({ date: '', time: '', description: '' });
  };

  return (
    <div className="container mx-auto mt-8 p-4 bg-transparent rounded-lg shadow-lg">
      <div className="mb-4 flex flex-col sm:flex-row justify-center items-center">
        <input
          type="date"
          value={newAppointment.date}
          onChange={(e) => setNewAppointment({ ...newAppointment, date: e.target.value })}
          className="border p-2 mb-2 sm:mb-0 sm:mr-2 rounded"
        />
        <input
          type="time"
          value={newAppointment.time}
          onChange={(e) => setNewAppointment({ ...newAppointment, time: e.target.value })}
          className="border p-2 mb-2 sm:mb-0 sm:mr-2 rounded"
        />
        <input
          type="text"
          placeholder="Description"
          value={newAppointment.description}
          onChange={(e) => setNewAppointment({ ...newAppointment, description: e.target.value })}
          className="border p-2 mb-2 sm:mb-0 sm:mr-2 rounded"
        />
        <button onClick={handleAddAppointment} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
          Add Appointment
        </button>
      </div>
      <div className="bg-gray-700 text-white rounded  p-4">
        <Calendar
          localizer={localizer}
          events={appointments}
          startAccessor="start"
          endAccessor="end"
          style={{ height: 700 }}
          className=""
        />
      </div>
    </div>
  );
};

export default MyCalendar;
