export default function Footer() {
    return (
      <footer className="fixed bottom-0 left-0 rounded-lg shadow z-10 pointer-events-none">
        <div className="w-full max-w-screen-xl px-4 md:py-8 mx-auto flex justify-between items-center">
          <div className="flex gap-2 pointer-events-auto">
            <a href="https://github.com/witedev" className="hover:text-sky-300 transition text-gray-500 dark:text-gray-400">
              <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="w-5" viewBox="0 0 16 16">
                <path d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.012 8.012 0 0 0 16 8c0-4.42-3.58-8-8-8z" />
              </svg>
            </a>
            <a href="https://www.linkedin.com/in/jes%C3%BAs-blanco-p%C3%A9rez-9843b2205/" className="hover:text-sky-300 transition text-gray-500 dark:text-gray-400">
              <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" fill="currentColor" className="w-6" viewBox="0 0 50 50">
                <path d="M41,4H9C6.24,4,4,6.24,4,9v32c0,2.76,2.24,5,5,5h32c2.76,0,5-2.24,5-5V9C46,6.24,43.76,4,41,4z M17,20v19h-6V20H17z M11,14.47c0-1.4,1.2-2.47,3-2.47s2.93,1.07,3,2.47c0,1.4-1.12,2.53-3,2.53C12.2,17,11,15.87,11,14.47z M39,39h-6c0,0,0-9.26,0-10 c0-2-1-4-3.5-4.04h-0.08C27,24.96,26,27.02,26,29c0,0.91,0,10,0,10h-6V20h6v2.56c0,0,1.93-2.56,5.81-2.56 c3.97,0,7.19,2.73,7.19,8.26V39z"></path>
              </svg>
            </a>
            <span className="self-center text-xl font-semibold whitespace-nowrap dark:text-white">WiteDev</span>
          </div>
          <span className="block text-sm ml-3 text-gray-500 sm:text-center dark:text-gray-400">© 2024 All Rights Reserved.</span>
        </div>
      </footer>
    );
  }
  