import React from 'react';
import LoginForm from "../Components/LoginForm";
import Footer from "../Components/Footer";
import { motion } from 'framer-motion';

export default function LoginPage() {
    return (
        <div className="flex flex-col h-screen items-center justify-center bg-[#05141f]">
            <motion.div 
                className="flex flex-col md:flex-row h-full w-full max-w-4xl items-center"
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 1 }}
            >
                <motion.div 
                    className="flex justify-center items-center md:w-1/2 w-full p-4"
                    initial={{ opacity: 0, x: -50 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 1, delay: 0.5 }}
                >
                    <img src={`${process.env.PUBLIC_URL}/favicon.ico`} alt="Astra Icon" width="200" height="200" />
                </motion.div>
                <motion.div 
                    className="flex justify-center items-center md:w-1/2 w-full p-4"
                    initial={{ opacity: 0, x: 50 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 1, delay: 0.5 }}
                >
                    <LoginForm />
                </motion.div>
            </motion.div>
            <Footer />
        </div>
    );
}
