import React, { useMemo, useState, useEffect } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import './datagrid.css';
import { FaEye, FaEyeSlash, FaCompress, FaExpand, FaTags } from 'react-icons/fa';
import MasonryGallery from './MasonryGallery';
import MasonryModal from './MasonryModal';
import StudyModal from './StudyModal';
import Modal from './Modal';
import TagsWindow from './TagsWindow';

const OpenStudy = ({ onOpen, studyUID, studyInstanceUID, showAll, isChild }) => {
  const handleClick = () => {
    if (isChild) {
      onOpen(studyInstanceUID, studyUID, false); // Petición para una única imagen
    } else {
      onOpen(studyInstanceUID, studyUID, showAll); // Petición para todas las imágenes
    }
  };

  return (
    <div className="action-buttons flex flex-row gap-3 mt-2 justify-center">
      <button
        onClick={handleClick}
        className="action-button text-white hover:text-gray-400 transition-transform transform hover:scale-110"
      >
        {isChild ? <FaEyeSlash className="h-4 w-4" /> : <FaEye className="h-4 w-4" />}
      </button>
    </div>
  );
};

const ExpandRow = ({ isExpanded, onExpand, studyUID }) => {
  return (
    <div className="action-buttons flex flex-row gap-3 justify-center mt-2">
      <button
        onClick={() => onExpand(studyUID)}
        className="action-button text-white hover:text-gray-400 transition-transform transform hover:scale-110"
      >
        {isExpanded ? <FaCompress className="h-4 w-4" /> : <FaExpand className="h-5 w-5" />}
      </button>
    </div>
  );
};

const TagsButton = ({ studyUID }) => {
  const [isTagsOpen, setIsTagsOpen] = useState(false);
  const [studyTags, setStudyTags] = useState([]);

  const openTags = async () => {
    try {
      const response = await fetch(`https://astrapacsserver.francecentral.cloudapp.azure.com:5149/getstudy?studyUid=${studyUID}`);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();

      // Parse the tags JSON string if it's a string
      let parsedTags = {};
      if (typeof data.tags === 'string') {
        parsedTags = JSON.parse(data.tags);
      } else {
        parsedTags = data.tags;
      }

      const tagsArray = Object.entries(parsedTags).map(([key, value]) => `${key}: ${value}`);
      console.log("Parsed tags:", tagsArray); // Para depurar
      setStudyTags(tagsArray);
      setIsTagsOpen(true);
    } catch (error) {
      console.error('Error fetching tags:', error);
    }
  };

  const closeTags = () => setIsTagsOpen(false);

  return (
    <div className="action-buttons flex flex-row gap-3 mt-2 justify-center">
      <button
        onClick={openTags}
        className="action-button text-white hover:text-gray-400 transition-transform transform hover:scale-110"
      >
        <FaTags className="h-4 w-4" />
      </button>
      {isTagsOpen && (
        <Modal isOpen={isTagsOpen} onClose={closeTags}>
          <TagsWindow tags={studyTags} />
        </Modal>
      )}
    </div>
  );
};


const StudiesDatagrid = ({ apiUrl }) => {
  const [rowData, setRowData] = useState([]);
  const [error, setError] = useState(null);
  const [isMasonryOpen, setIsMasonryOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(apiUrl);
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        const groupedData = groupDataByStudyInstanceUID(data);
        setRowData(groupedData);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError(error.message);
      }
    };

    fetchData();
  }, [apiUrl]);

  const openStudy = async (studyInstanceUID, studyUID, showAll) => {
    try {
      const endpoint = showAll
        ? `${process.env.REACT_APP_API_URL}/getstudybyinstanceuid?studyInstanceUid=${studyInstanceUID}`
        : `${process.env.REACT_APP_API_URL}/getstudy?studyUid=${studyUID}`;
      const response = await fetch(endpoint);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      if (showAll) {
        setSelectedImages(data.images);
        setSelectedTags(data.tags);
        setIsMasonryOpen(true);
      } else {
        setSelectedImages([data.study]); // Wrap in an array
        setSelectedTags(data.tags);
        setSelectedImage(data.study);
        setIsModalOpen(true);
      }
    } catch (error) {
      console.error('Error fetching study:', error);
      setError(error.message);
    }
  };

  const toggleExpand = (studyUID) => {
    setRowData((prevRowData) => {
      return prevRowData.map((item) => {
        if (item.studyInstanceUID === studyUID) {
          item.isExpanded = !item.isExpanded;
          if (item.isExpanded) {
            item.children.forEach((child) => (child.show = true));
          } else {
            item.children.forEach((child) => (child.show = false));
          }
        }
        return item;
      });
    });
  };

  const groupDataByStudyInstanceUID = (data) => {
    const groupedData = [];
    const studyInstanceUIDMap = {};

    data.forEach((item) => {
      if (!studyInstanceUIDMap[item.studyInstanceUID]) {
        studyInstanceUIDMap[item.studyInstanceUID] = {
          studyInstanceUID: item.studyInstanceUID,
          rowType: 'parent',
          isExpanded: false,
          modalities: [],
          seriesDescriptions: [],
          patientName: item.patientName,
          accessionNumber: item.accessionNumber,
          patientIdentifier: item.patientIdentifier,
          patientSex: item.patientSex,
          patientEthnicGroup: item.patientEthnicGroup,
          acquisitionDate: item.acquisitionDate,
          callingAE: item.callingAE,
          children: []
        };
        groupedData.push(studyInstanceUIDMap[item.studyInstanceUID]);
      }

      if (!studyInstanceUIDMap[item.studyInstanceUID].modalities.includes(item.modality)) {
        studyInstanceUIDMap[item.studyInstanceUID].modalities.push(item.modality);
      }

      if (!studyInstanceUIDMap[item.studyInstanceUID].seriesDescriptions.includes(item.seriesDescription)) {
        studyInstanceUIDMap[item.studyInstanceUID].seriesDescriptions.push(item.seriesDescription);
      }

      studyInstanceUIDMap[item.studyInstanceUID].children.push({
        ...item,
        rowType: 'child',
        show: false,
        examTime: item.acquisitionTime,
        tags: item.tags, // Add tags to children
      });
    });

    return groupedData;
  };

  const closeMasonry = () => {
    setIsMasonryOpen(false);
    setSelectedImages([]);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedImage(null);
  };

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      editable: false,
      filter: true,
    };
  }, []);

  const colDefs = [
    {
      field: 'actions',
      headerName: 'Actions', flex: 0.75,
      cellRenderer: (params) => {
        if (params.data.rowType === 'parent') {
          return (
            <div className="flex items-center gap-3">
              <OpenStudy
                studyUID={params.data.studyUid}
                studyInstanceUID={params.data.studyInstanceUID}
                onOpen={openStudy}
                showAll={true}
              />
              <ExpandRow
                studyUID={params.data.studyInstanceUID}
                isExpanded={params.data.isExpanded}
                onExpand={toggleExpand}
              />
              
            </div>
          );
        } else if (params.data.rowType === 'child') {
          return (
            <div className="flex gap-3 ml-6">
              <OpenStudy
                studyUID={params.data.studyUid}
                studyInstanceUID={params.data.studyInstanceUID}
                onOpen={openStudy}
                showAll={false}
                isChild={true}
              />
              <TagsButton tags={params.data.tags} studyUID={params.data.studyUid} />
            </div>
          );
        }
        return null;
      },
    },
    { field: 'accessionNumber', headerName: 'Accession Number' },
    { field: 'patientIdentifier', headerName: 'Patient Identifier' },
    { field: 'patientName', headerName: 'Patient Name', flex: 3 },
    // { field: 'patientBirthDate', headerName: 'Patient Birth Date' },
    // { field: 'patientSex', headerName: 'Patient Sex' },
    // { field: 'patientEthnicGroup', headerName: 'Patient Ethnic Group' },
    { field: 'acquisitionDate', headerName: 'Exam Date' },
    { field: 'examTime', headerName: 'Exam Time', valueGetter: (params) => params.data.examTime, hide: (params) => params.data.rowType === 'parent' },
    { field: 'callingAE', headerName: 'Calling AET' },
    { field: 'modalities', headerName: 'Modalities', valueGetter: (params) => params.data.rowType === 'parent' ? (params.data.modalities || []).join(', ') : params.data.modality, hide: (params) => params.data.rowType === 'child' },
    { field: 'seriesDescriptions', headerName: 'Series Descriptions', valueGetter: (params) => params.data.rowType === 'parent' ? (params.data.seriesDescriptions || []).join(', ') : params.data.seriesDescription, hide: (params) => params.data.rowType === 'child' },
    { field: 'sopClassUID', headerName: 'SOP Class UID', hide: (params) => params.data.rowType === 'parent' },
    { field: 'studyInstanceUID', headerName: 'Study Instance UID' },
    { field: 'childCount', headerName: 'Total', flex: 0.7, valueGetter: (params) => params.data.rowType === 'parent' ? params.data.children.length : '', hide: (params) => params.data.rowType === 'child' }
  ];

  const getRowClass = (params) => {
    if (params.data.rowType === 'parent') {
      return 'parent-row transition-height duration-300 ease-in-out';
    } else if (params.data.rowType === 'child') {
      return 'child-row transition-height duration-300 ease-in-out';
    }
    return '';
  };

  const getRowHeight = (params) => {
    if (params.data.rowType === 'child') {
      return 30; // Altura de las filas secundarias
    }
    return 45; // Altura de las filas principales
  };

  return (
    <div className="ag-theme-quartz-dark h-screen bg-transparent">
      <AgGridReact
        rowData={rowData.flatMap((item) => (item.isExpanded ? [item, ...item.children.filter((child) => child.show)] : [item]))}
        columnDefs={colDefs}
        pagination={true}
        rowSelection="multiple"
        paginationPageSize={100}
        defaultColDef={defaultColDef}
        getRowClass={getRowClass}
        getRowHeight={getRowHeight}
      />
      {isMasonryOpen && (
        <MasonryModal isOpen={isMasonryOpen} onClose={closeMasonry}>
          <MasonryGallery
            images={selectedImages}
            tags={selectedTags}
            isOpen={isMasonryOpen}
            onClose={closeMasonry}
          />
        </MasonryModal>
      )}
      {isModalOpen && (
        <StudyModal
          isOpen={isModalOpen}
          onClose={closeModal}
          images={selectedImages}
          selectedImage={selectedImage}
          onSelectImage={setSelectedImage}
        />
      )}
    </div>
  );
};

export default StudiesDatagrid;
