import React, { useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { HomeIcon, ChartBarIcon, ServerStackIcon, ChatBubbleLeftEllipsisIcon , UserIcon, DocumentTextIcon, ClipboardDocumentListIcon, CogIcon, UserCircleIcon, InformationCircleIcon, ArrowRightOnRectangleIcon, UserGroupIcon, CalendarDaysIcon } from '@heroicons/react/24/outline';
import Icon from '../Assets/Icon';
import { useAuth } from './AuthContext';
import Modal from '../Components/Modal';
import RegisterPatientForm from '../Windows/RegisterPatient';
import SearchPatientForm from '../Windows/SearchPatientForm';
import RegisterClientForm from '../Windows/RegisterClientsForm';
import RetrieveWorklistForm from '../Windows/RetrieveWorklistForm';

export default function Header() {
  const [isWorklistOpen, setIsWorklistOpen] = useState(false);
  const [isStudiesOpen, setIsStudiesOpen] = useState(false);
  const [isPatientsOpen, setIsPatientsOpen] = useState(false);
  const [isClientsOpen, setIsClientsOpen] = useState(false);
  const [isCreatePatientModalOpen, setIsCreatePatientModalOpen] = useState(false);
  const [isSearchPatientModalOpen, setIsSearchPatientModalOpen] = useState(false);
  const [isCreateClientModalOpen, setIsCreateClientModalOpen] = useState(false);
  const [isRetrieveWorklistModalOpen, setIsRetrieveWorklistModalOpen] = useState(false);

  const { logout } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const handleLogout = () => {
    logout();
    navigate('/');
  };

  const toggleWorklist = () => {
    setIsWorklistOpen(!isWorklistOpen);
    setIsStudiesOpen(false);
    setIsPatientsOpen(false);
    setIsClientsOpen(false);
  };

  const toggleStudies = () => {
    setIsStudiesOpen(!isStudiesOpen);
    setIsWorklistOpen(false);
    setIsPatientsOpen(false);
    setIsClientsOpen(false);
  };

  const togglePatients = () => {
    setIsPatientsOpen(!isPatientsOpen);
    setIsWorklistOpen(false);
    setIsStudiesOpen(false);
    setIsClientsOpen(false);
  };

  const toggleClients = () => {
    setIsClientsOpen(!isClientsOpen);
    setIsWorklistOpen(false);
    setIsStudiesOpen(false);
    setIsPatientsOpen(false);
  };

  const handleCreatePatientOpenModal = () => {
    setIsCreatePatientModalOpen(true);
  };

  const handleRetrieveWorklistOpenModal = () => {
    setIsRetrieveWorklistModalOpen(true);
  };

  const handleSearchPatientOpenModal = () => {
    setIsSearchPatientModalOpen(true);
  };

  const handleCreateClientOpenModal = () => {
    setIsCreateClientModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsCreatePatientModalOpen(false);
    setIsSearchPatientModalOpen(false);
    setIsCreateClientModalOpen(false);
    setIsRetrieveWorklistModalOpen(false);
  };

  const handlePatientSubmit = (result) => {
    console.log('Patient created:', result);
    handleCloseModal();
  };

  const handleClientSubmit = (result) => {
    console.log('Client created:', result);
    handleCloseModal();
  };

  const handleRetrieveWorklist = (result) => {
    console.log('Worklist retrieved:', result);
    handleCloseModal();
  };

  const handleLinkClick = (path) => {
    navigate(path);
    window.location.reload();
  };

  const isActiveRoute = (route) => location.pathname === route;

  return (
    <>
      <div className="navbar bg-transparent">
        <div className="navbar-start">
          <div className="dropdown">
            <div tabIndex={0} role="button" className="btn btn-ghost btn-circle">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-white" fill="white" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h7" />
              </svg>
            </div>
            <ul tabIndex={0} className="menu menu-sm dropdown-content mt-3 z-[1] p-2 shadow bg-opacity-100 bg-base-100 rounded-box w-52">
              <li>
                <Link
                  to="/Home"
                  onClick={() => handleLinkClick('/Home')}
                  className={`${isActiveRoute('/Home') ? 'bg-[#05141f] text-white' : ''}`}
                >
                  <HomeIcon className="h-5 w-5 mr-2" />
                  Home
                </Link>
              </li>
              <li>
                <Link
                  to="/Dashboard"
                  onClick={() => handleLinkClick('/Dashboard')}
                  className={`${isActiveRoute('/Dashboard') ? 'bg-[#05141f] text-white' : ''}`}
                >
                  <ChartBarIcon className="h-5 w-5 mr-2" />
                  Dashboard
                </Link>
              </li>
              <li>
                <Link
                  to="/Server"
                  onClick={() => handleLinkClick('/Server')}
                  className={`${isActiveRoute('/Server') ? 'bg-[#05141f] text-white' : ''}`}
                >
                  <ServerStackIcon className="h-5 w-5 mr-2" />
                  Server
                </Link>
              </li>
              {/* <li>
                <Link
                  to="/Calendar"
                  onClick={() => handleLinkClick('/Calendar')}
                  className={`${isActiveRoute('/Calendar') ? 'bg-[#05141f] text-white' : ''}`}
                >
                  <CalendarDaysIcon className="h-5 w-5 mr-2" />
                  Calendar
                </Link>
              </li> */}
              <li>
                <div onClick={togglePatients} role="button" className="w-full text-left flex items-center">
                  <UserIcon className="h-5 w-5 mr-2" />
                  Patients
                  <svg xmlns="http://www.w3.org/2000/svg" className={`h-5 w-5 ml-auto transform ${isPatientsOpen ? 'rotate-180' : ''}`} fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" d="M19 9l-7 7-7-7" />
                  </svg>
                </div>
                {isPatientsOpen && (
                  <ul className="menu menu-sm mt-1 p-2 w-full">
                    <li>
                      <Link
                        to="/SearchPatients"
                        onClick={() => handleLinkClick('/SearchPatients')}
                        className={`${isActiveRoute('/SearchPatients') ? 'bg-[#05141f] text-white' : ''}`}
                      >
                        Search
                      </Link>
                    </li>
                    <li>
                      <button onClick={handleCreatePatientOpenModal}>Create</button>
                    </li>
                    <li>
                      <Link
                        to="/TodayPatients"
                        onClick={() => handleLinkClick('/TodayPatients')}
                        className={`${isActiveRoute('/TodayPatients') ? 'bg-[#05141f] text-white' : ''}`}
                      >
                        Today
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/Last7DaysPatients"
                        onClick={() => handleLinkClick('/Last7DaysPatients')}
                        className={`${isActiveRoute('/Last7DaysPatients') ? 'bg-[#05141f] text-white' : ''}`}
                      >
                        Last 7 days
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/HistoricPatients"
                        onClick={() => handleLinkClick('/HistoricPatients')}
                        className={`${isActiveRoute('/HistoricPatients') ? 'bg-[#05141f] text-white' : ''}`}
                      >
                        Historic
                      </Link>
                    </li>
                  </ul>
                )}
              </li>
              <li>
                <div onClick={toggleStudies} role="button" className="w-full text-left flex items-center">
                  <DocumentTextIcon className="h-5 w-5 mr-2" />
                  Studies
                  <svg xmlns="http://www.w3.org/2000/svg" className={`h-5 w-5 ml-auto transform ${isStudiesOpen ? 'rotate-180' : ''}`} fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" d="M19 9l-7 7-7-7" />
                  </svg>
                </div>
                {isStudiesOpen && (
                  <ul className="menu menu-sm mt-1 p-2 w-full">
                    <li>
                      <Link
                        to="/SearchStudy"
                        onClick={() => handleLinkClick('/SearchStudy')}
                        className={`${isActiveRoute('/SearchStudy') ? 'bg-[#05141f] text-white' : ''}`}
                      >
                        Search
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/TodayStudies"
                        onClick={() => handleLinkClick('/TodayStudies')}
                        className={`${isActiveRoute('/TodayStudies') ? 'bg-[#05141f] text-white' : ''}`}
                      >
                        Today
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/Last7DaysStudies"
                        onClick={() => handleLinkClick('/Last7DaysStudies')}
                        className={`${isActiveRoute('/Last7DaysStudies') ? 'bg-[#05141f] text-white' : ''}`}
                      >
                        Last 7 days
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/HistoricStudies"
                        onClick={() => handleLinkClick('/HistoricStudies')}
                        className={`${isActiveRoute('/HistoricStudies') ? 'bg-[#05141f] text-white' : ''}`}
                      >
                        Historic
                      </Link>
                    </li>
                  </ul>
                )}
              </li>
              <li>
                <div onClick={toggleWorklist} role="button" className="w-full text-left flex items-center">
                  <ClipboardDocumentListIcon className="h-5 w-5 mr-2" />
                  Worklist
                  <svg xmlns="http://www.w3.org/2000/svg" className={`h-5 w-5 ml-auto transform ${isWorklistOpen ? 'rotate-180' : ''}`} fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" d="M19 9l-7 7-7-7" />
                  </svg>
                </div>
                {isWorklistOpen && (
                  <ul className="menu menu-sm mt-1 p-2 w-full">
                    <li>
                      <Link
                        to="/QueryWorklist"
                        onClick={() => handleLinkClick('/QueryWorklist')}
                        className={`${isActiveRoute('/QueryWorklist') ? 'bg-[#05141f] text-white' : ''}`}
                      >
                        Query
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/TodayWorklist"
                        onClick={() => handleLinkClick('/TodayWorklist')}
                        className={`${isActiveRoute('/TodayWorklist') ? 'bg-[#05141f] text-white' : ''}`}
                      >
                        Today
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/Last7DaysWorklist"
                        onClick={() => handleLinkClick('/Last7DaysWorklist')}
                        className={`${isActiveRoute('/Last7DaysWorklist') ? 'bg-[#05141f] text-white' : ''}`}
                      >
                        Last 7 days
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/HistoricWorklist"
                        onClick={() => handleLinkClick('/HistoricWorklist')}
                        className={`${isActiveRoute('/HistoricWorklist') ? 'bg-[#05141f] text-white' : ''}`}
                      >
                        Historic
                      </Link>
                    </li>
                  </ul>
                )}
              </li>
              <li>
                <div onClick={toggleClients} role="button" className="w-full text-left flex items-center">
                  <UserGroupIcon className="h-5 w-5 mr-2" />
                  Clients
                  <svg xmlns="http://www.w3.org/2000/svg" className={`h-5 w-5 ml-auto transform ${isClientsOpen ? 'rotate-180' : ''}`} fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" d="M19 9l-7 7-7-7" />
                  </svg>
                </div>
                {isClientsOpen && (
                  <ul className="menu menu-sm mt-1 p-2 w-full">
                    <li>
                      <button
                        onClick={handleCreateClientOpenModal}
                        className={`${isActiveRoute('/ListClient') ? 'bg-[#05141f] text-white' : ''}`}
                      >
                        Create
                      </button>
                    </li>
                    <li>
                      <Link
                        to="/ListClient"
                        onClick={() => handleLinkClick('/ListClient')}
                        className={`${isActiveRoute('/ListClient') ? 'bg-[#05141f] text-white' : ''}`}
                      >
                        List
                      </Link>
                    </li>
                  </ul>
                )}
              </li>
              <li>
                <Link
                  to="/Chat"
                  onClick={() => handleLinkClick('/Chat')}
                  className={`${isActiveRoute('/Chat') ? 'bg-[#05141f] text-white' : ''}`}
                >
                  <ChatBubbleLeftEllipsisIcon className="h-5 w-5 mr-2" />
                  Chat
                </Link>
              </li>
              {/* <li>
                <Link
                  to="/Settings"
                  onClick={() => handleLinkClick('/Settings')}
                  className={`${isActiveRoute('/Settings') ? 'bg-[#05141f] text-white' : ''}`}
                >
                  <CogIcon className="h-5 w-5 mr-2" />
                  Settings
                </Link>
              </li>
              <li>
                <Link
                  to="/Profile"
                  onClick={() => handleLinkClick('/Profile')}
                  className={`${isActiveRoute('/Profile') ? 'bg-[#05141f] text-white' : ''}`}
                >
                  <UserCircleIcon className="h-5 w-5 mr-2" />
                  Profile
                </Link>
              </li>
              <li>
                <Link
                  to="/About"
                  onClick={() => handleLinkClick('/About')}
                  className={`${isActiveRoute('/About') ? 'bg-[#05141f] text-white' : ''}`}
                >
                  <InformationCircleIcon className="h-5 w-5 mr-2" />
                  About
                </Link>
              </li> */}
            </ul>
          </div>
        </div>
        <div className="navbar-center">
          <a
          onClick={() => handleLinkClick('/Home')}
          className="transition-transform transform hover:scale-110 cursor-pointer"
        >
          <Icon height={56} width={56} />
        </a>
        </div>
        <div className="navbar-end">
          <button onClick={handleLogout} className="btn btn-ghost text-white items-center justify-items-center btn-circle">
            <ArrowRightOnRectangleIcon className="h-5 w-5" />
          </button>
        </div>
      </div>
      <Modal isOpen={isCreatePatientModalOpen} onClose={handleCloseModal}>
        <RegisterPatientForm onSubmit={handlePatientSubmit} />
      </Modal>
      <Modal isOpen={isSearchPatientModalOpen} onClose={handleCloseModal}>
        <SearchPatientForm onSubmit={handleSearchPatientOpenModal} />
      </Modal>
      <Modal isOpen={isCreateClientModalOpen} onClose={handleCloseModal}>
        <RegisterClientForm onSubmit={handleClientSubmit} />
      </Modal>
      <Modal isOpen={isRetrieveWorklistModalOpen} onClose={handleCloseModal}>
        <RetrieveWorklistForm onRetrieve={handleRetrieveWorklist} />
      </Modal>
    </>
  );
}