import React, { useState, useEffect } from 'react';

const SearchPatientForm = ({ onSearch }) => {
  const [formData, setFormData] = useState({
    identifier: '',
    surname: '',
    forename: ''
  });

  useEffect(() => {
    console.log("SearchPatientForm mounted with onSearch:", onSearch);
  }, [onSearch]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (typeof onSearch === 'function') {
      console.log("Calling onSearch with", formData);
      onSearch(formData);
    } else {
      console.error("onSearch is not a function");
    }
  };

  return (
    <form className="flex flex-col space-y-4" onSubmit={handleSubmit}>
      <div>
        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="identifier">
          Patient Identifier
        </label>
        <input
          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
          id="identifier"
          type="text"
          name="identifier"
          value={formData.identifier}
          onChange={handleChange}
          placeholder="Patient Identifier"
        />
      </div>
      <div>
        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="surname">
          Surname
        </label>
        <input
          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
          id="surname"
          type="text"
          name="surname"
          value={formData.surname}
          onChange={handleChange}
          placeholder="Surname"
        />
      </div>
      <div>
        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="forename">
          Forename
        </label>
        <input
          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
          id="forename"
          type="text"
          name="forename"
          value={formData.forename}
          onChange={handleChange}
          placeholder="Forename"
        />
      </div>
      <button type="submit" className="btn btn-ghost btn-circle">
        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
        </svg>
      </button>
    </form>
  );
};

export default SearchPatientForm;
