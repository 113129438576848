'use strict';
import React, { useMemo } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import './datagrid.css';

const Send2Worklist = (p) => {
    return (
        <>
            <button onClick={() => window.alert("Action!")}>+</button>
            {p.value}
        </>
    );
};

const SearchedWorklistDatagrid = ({ rowData }) => {
    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            editable: false,
            filter: true,
        };
    }, []);

    const colDefs = useMemo(() => [
        { field: "actions", headerName: "Actions", cellRenderer: Send2Worklist },
        { field: "accessionNumber", headerName: "Accession Number" },
        { field: "examDescription", headerName: "Scheduled Procedure Description" },
        { field: "patientIdentifier", headerName: "Patient Identifier" },
        { field: "patientName", headerName: "Name" },
        { field: "patientSex", headerName: "Sex" },
        { field: "patientEthnicGroup", headerName: "Ethnicity" },
        { field: "modality", headerName: "Modality" },
        { field: "examDate", headerName: "Study Date" },
        { field: "examTime", headerName: "Study Time" },
        { field: "scheduledAET", headerName: "Scheduled AE Title" },
        { field: "studyInstanceUid", headerName: "Study Instance UID" },
    ], []);

    return (
        <div className='ag-theme-quartz-dark h-screen bg-transparent'>
            <AgGridReact
                rowData={rowData}
                columnDefs={colDefs}
                pagination={true}
                rowSelection='multiple'
                paginationPageSize={20}
                defaultColDef={defaultColDef}
            />
        </div>
    );
};

export default SearchedWorklistDatagrid;
