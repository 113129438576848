import React, { useState } from 'react';
import Modal from '../../Components/Modal';
import RetrieveWorklistForm from '../../Windows/RetrieveWorklistForm';
import SearchedWorklistDatagrid from '../../Components/SearchedWorklistDatagrid';
import Layout from '../../Layouts/Layouts';

const SearchWorklistPage = () => {
  const [isRetrieveWorklistModalOpen, setIsRetrieveWorklistModalOpen] = useState(true);
  const [worklistData, setWorklistData] = useState([]);

  const handleCloseModal = () => {
    setIsRetrieveWorklistModalOpen(false);
  };

  const handleRetrieveWorklist = (data) => {
    setWorklistData(data);
    handleCloseModal();
  };

  return (
    <Layout>
      <Modal isOpen={isRetrieveWorklistModalOpen} onClose={handleCloseModal}>
        <RetrieveWorklistForm onRetrieve={handleRetrieveWorklist} />
      </Modal>
      <SearchedWorklistDatagrid rowData={worklistData} />
    </Layout>
  );
};

export default SearchWorklistPage;
