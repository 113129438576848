import React from 'react';

const MasonryModal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center max-h-screen bg-black bg-opacity-80">
      <div className="relative flex flex-col items-center justify-center bg-transparent rounded-lg p-10 overflow-auto transform transition-all w-full h-full">
        <button className="absolute top-0 right-0 m-4 btn btn-ghost btn-circle text-white text-3xl " onClick={onClose}>
          &times;
        </button>
        {children}
      </div>
    </div>
  );
};

export default MasonryModal;
