import React, { useState } from 'react';
import Layout from "../../Layouts/Layouts";
import Modal from '../../Components/Modal';
import SearchedPatientsDatagrid from '../../Components/SearchedPatientDatagrid';
import SearchPatientForm from "../../Windows/SearchPatientForm";

const SearchPatientsPage = () => {
  const [isSearchPatientModalOpen, setIsSearchPatientModalOpen] = useState(true);
  const [patients, setPatients] = useState([]);

  const handleCloseModal = () => {
    setIsSearchPatientModalOpen(false);
  };

  const handleSearch = async (searchParams) => {
    console.log("handleSearch called with", searchParams);
    
    const response = await fetch(`${process.env.REACT_APP_API_URL}/searchpatients`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(searchParams)
    });

    const data = await response.json();
    setPatients(data);
    handleCloseModal();
  };

  return (
    <Layout>
      <Modal isOpen={isSearchPatientModalOpen} onClose={handleCloseModal}>
        <SearchPatientForm onSearch={handleSearch} />
      </Modal>
      <SearchedPatientsDatagrid patients={patients} />
    </Layout>
  );
};

export default SearchPatientsPage;
