import React from 'react';

const ConfirmationModal = ({ isOpen, onClose, onConfirm, title, message }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white rounded-lg shadow-lg w-11/12 md:w-2/3 lg:w-1/2 xl:w-1/3 max-h-screen overflow-y-auto">
        <div className="flex justify-end p-2">
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        <div className="p-4">
          <h2 className="text-lg text-gray-500 font-bold">{title}</h2>
          <p className="mt-2 text-gray-500">{message}</p>
          <div className="flex justify-end space-x-2 mt-4">
            <button onClick={onConfirm} className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-700">
              Yes
            </button>
            <button onClick={onClose} className="bg-indigo-500 text-white px-4 py-2 rounded hover:bg-indigo-700">
              No
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
