import React from 'react';
import Layout from "../Layouts/Layouts";
import Icon from "../Assets/Icon"; // Asegúrate de que este es el camino correcto a tu icono

export default function HomePage() { 
    return (
        <Layout> 
            <div className="flex flex-col md:flex-row items-center justify-center h-full p-8 ">
                <div className="md:w-1/2 p-4 text-center bg-gradient-to-r text md:text-left">
                    <h1 className="text-8xl text-white mb-4 fade-in">
                        Astra
                    </h1>
                    <p className="text-lg text-gray-400 animate-fade-in" style={{ animationDelay: '1s' }}>
                        Enhance your medical imaging workflow with cutting-edge technology and intuitive design. 
                    </p>
                </div>
                    

            </div>
        </Layout>
    );
}
