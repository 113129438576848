import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';

const Send2WorklistForm = ({ onSubmit, patientData }) => {
  const getCurrentDateTime = () => new Date();

  const formatDateForInput = (date) => date.toISOString().split('T')[0]; // "YYYY-MM-DD"

  const formatTimeForInput = (date) => {
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
  };

  const [formData, setFormData] = useState({
    ip: '4.233.192.183',
    port: 1011,
    scuaeTitle: ' ',
    scpAeTitle: 'RS_ASTRA',
    patId: patientData.patId,
    surname: patientData.surname,
    forename: patientData.forename,
    dob: formatDateForInput(patientData.dob ? new Date(patientData.dob) : getCurrentDateTime()),
    sex: patientData.sex.charAt(0),
    ethnicGroup: patientData.ethnicity,
    Modality: patientData.modality,
    examDate: formatDateForInput(patientData.examDate ? moment(patientData.examDate).toDate() : getCurrentDateTime()),
    examTime: formatTimeForInput(patientData.examTime ? moment(patientData.examTime, 'HH:mm:ss').toDate() : getCurrentDateTime()), // Ajusta según la zona horaria local
    examDescription: patientData.studyDescription,
    scheduledProcedureStepDescription: patientData.examDescription,
    scheduledAET: '',
  });

  const [scheduledAETitles, setScheduledAETitles] = useState([]);

  useEffect(() => {
    // Fetch the calling AETitles
    fetch(`${process.env.REACT_APP_API_URL}/callingaetitles`)
      .then((response) => response.json())
      .then((data) => {
        // Eliminate duplicates
        const uniqueAETitles = data.filter((v, i, a) => a.findIndex((t) => t.aeTitle === v.aeTitle && t.description === v.description) === i);
        setScheduledAETitles(uniqueAETitles);
      })
      .catch((error) => {
        console.error('Error fetching calling AETitles:', error);
      });
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === 'ScheduledAETitle') {
      const selectedAET = scheduledAETitles.find((aet) => aet.aeTitle === value);
      setFormData({
        ...formData,
        scheduledAET: value,
        scuaeTitle: value,
        examDescription: selectedAET?.description || '',
        scheduledProcedureStepDescription: selectedAET?.description || '',
        Modality: selectedAET?.allowedModality || '',
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Combine examDate and examTime into a single Date object considering the local timezone
    const combinedDateTime = new Date(`${formData.examDate}T${formData.examTime}:00`);
    const dataToSend = {
      ...formData,
      examDate: combinedDateTime.toISOString(),
      examTime: combinedDateTime.toISOString(),
    };

    fetch(`${process.env.REACT_APP_API_URL}/createpatientinworklist`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(dataToSend),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log('Patient registered successfully:', data);
        onSubmit(); // Llamada para cerrar el modal después de enviar el formulario
      })
      .catch((error) => {
        console.error('Error registering patient:', error);
      });
  };

  return (
    <form className="w-full max-w-lg mx-auto grid grid-cols-2 gap-6" onSubmit={handleSubmit}>
      {/* Add form fields */}
      <div className="col-span-2">
        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="patId">
          Patient Identifier
        </label>
        <input
          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
          id="patId"
          type="text"
          name="patId"
          value={formData.patId}
          onChange={handleChange}
          placeholder="Patient Identifier"
          required
        />
      </div>
      <div>
        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="surname">
          Surname
        </label>
        <input
          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
          id="surname"
          type="text"
          name="surname"
          value={formData.surname}
          onChange={handleChange}
          placeholder="Surname"
          required
        />
        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="forename">
          Forename
        </label>
        <input
          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
          id="forename"
          type="text"
          name="forename"
          value={formData.forename}
          onChange={handleChange}
          placeholder="Forename"
          required
        />
      </div>
      <div>
        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="dob">
          Birth Date
        </label>
        <input
          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
          id="dob"
          type="date"
          name="dob"
          value={formData.dob}
          onChange={handleChange}
          placeholder="Birth Date"
          required
        />
      </div>
      <div>
        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="sex">
          Sex
        </label>
        <div className="relative">
          <select
            className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            id="sex"
            name="sex"
            value={formData.sex}
            onChange={handleChange}
            required
          >
            <option value="">Select sex</option>
            <option value="M">Male</option>
            <option value="F">Female</option>
            <option value="O">Other</option>
          </select>
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
            <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
              <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
            </svg>
          </div>
        </div>
      </div>
      <div>
        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="ethnicity">
          Ethnicity
        </label>
        <div className="relative">
          <select
            className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            id="ethnicity"
            name="ethnicGroup"
            value={formData.ethnicGroup}
            onChange={handleChange}
            required
          >
            <option value="">Select ethnicity</option>
            <option value="African">African</option>
            <option value="Caucasian">Caucasian</option>
            <option value="Hispanic">Hispanic</option>
            <option value="Asian">Asian</option>
          </select>
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
            <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
              <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
            </svg>
          </div>
        </div>
      </div>
      <div>
        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="StudyDate">
          Study Date
        </label>
        <input
          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
          id="StudyDate"
          type="date"
          name="examDate"
          value={formData.examDate}
          onChange={handleChange}
          placeholder="Study Date"
        />
      </div>
      <div>
        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="StudyTime">
          Study Time
        </label>
        <input
          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
          id="StudyTime"
          type="time"
          name="examTime"
          value={formData.examTime}
          onChange={handleChange}
          placeholder="Study Time"
        />
      </div>
      <div className="col-span-2">
        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="ScheduledAETitle">
          Scheduled AE Title
        </label>
        <div className="relative">
          <select
            className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            id="ScheduledAETitle"
            name="ScheduledAETitle"
            value={formData.scheduledAET}
            onChange={handleChange}
            required
          >
            <option value="">Select Scheduled AE Title</option>
            {scheduledAETitles.map((aet) => (
              <option key={aet.callingAETUid} value={aet.aeTitle}>
                {aet.description ? `${aet.description} (${aet.aeTitle})` : aet.aeTitle}
              </option>
            ))}
          </select>
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
            <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
              <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
            </svg>
          </div>
        </div>
      </div>
      <div>
        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="Modality">
          Modality
        </label>
        <input
          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
          id="Modality"
          type="text"
          name="Modality"
          value={formData.Modality}
          onChange={handleChange}
          placeholder="Modality"
        />
      </div>
      <div>
        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="StudyDescription">
          Study Description
        </label>
        <input
          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
          id="StudyDescription"
          type="text"
          name="StudyDescription"
          value={formData.examDescription}
          onChange={handleChange}
          placeholder="Study Description"
        />
      </div>
      <div className="col-span-2">
        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="ScheduledProcedureDescription">
          Scheduled Procedure Step Description
        </label>
        <input
          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
          id="ScheduledProcedureDescription"
          type="text"
          name="ScheduledProcedureStepDescription"
          value={formData.scheduledProcedureStepDescription}
          onChange={handleChange}
          placeholder="Scheduled Procedure Description"
        />
      </div>
      <div className="col-span-2 flex justify-end">
        <button
          type="submit"
          className="bg-indigo-500 text-white px-4 py-2 rounded-md shadow-sm hover:bg-indigo-700"
        >
          Register
        </button>
      </div>
    </form>
  );
};

export default Send2WorklistForm;
