import React, { useState, useMemo } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import './datagrid.css';
import Modal from '../Components/Modal';
import Send2WorklistForm from '../Windows/Send2Worklist'; // Asegúrate de la ruta correcta
import { PaperAirplaneIcon, PencilIcon, XCircleIcon } from '@heroicons/react/24/solid'; // Asegúrate de tener Heroicons instalado

const SearchedPatientsDatagrid = ({ patients }) => {
  const [isSendWorklistPatientModalOpen, setIsSendWorklistPatientModalOpen] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState(null);

  const handleSendWorklistPatientOpenModal = (patient) => {
    setSelectedPatient(patient);
    setIsSendWorklistPatientModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsSendWorklistPatientModalOpen(false);
  };

  const handleDeletePatientEntry = async (accessionNumber) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/deleteworklistentry?accessionNumber=${accessionNumber}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      console.log('Entry deleted successfully');
      // Optionally, refresh the grid data here
    } catch (error) {
      console.error('Failed to delete entry:', error);
    }
  };

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      editable: false,
      filter: true,
    };
  }, []);

  const Send2Worklist = (p) => {
    return (
      <>
        <button
          onClick={() => handleSendWorklistPatientOpenModal(p.data)}
          className="flex items-center text-xs mt-2 text-blue-500 hover:text-blue-700"
        >
          <PaperAirplaneIcon className="h-5 w-5" />
        </button>
      </>
    );
  };

  const ActionButtons = (p) => {
    return (
        <div className="action-buttons flex flex-row gap-3 mt-2">
            <button
                onClick={() => handleDeletePatientEntry(p.data.accessionNumber)}
                className="action-button text-yellow-300 hover:text-yellow-700"
            >
                <PencilIcon className="h-5 w-5" />
            </button>
            <button
                onClick={() => handleDeletePatientEntry(p.data.accessionNumber)}
                className="action-button text-red-500 hover:text-red-700"
            >
                <XCircleIcon className="h-5 w-5" />
            </button>
        </div>
    );
}

  const colDefs = useMemo(() => [
    { field: "actions", headerName: "Actions", cellRenderer: Send2Worklist },
    { field: "identifier", headerName: "Patient Identifier" },
    { field: "surName", headerName: "Surname" },
    { field: "foreName", headerName: "Forename" },
    { field: "sex", headerName: "Sex" },
    { field: "birthDate", headerName: "Birth Date" },
    { field: "ethnicGroup", headerName: "Ethnicity" },
    { field: "address", headerName: "Address" },
    { field: "city", headerName: "City" },
    { field: "state", headerName: "State" },
    { field: "postalCode", headerName: "Postal Code" },
    { field: "country", headerName: "Country" },
    {field: "actions", headerName: "Actions", cellRenderer: ActionButtons,}
  ], []);

  return (
    <>
      <div className='ag-theme-quartz-dark h-screen bg-transparent'>
        <AgGridReact
          rowData={patients}
          columnDefs={colDefs}
          pagination={true}
          rowSelection='multiple'
          paginationPageSize={20}
          defaultColDef={defaultColDef}
        />
      </div>

      <Modal isOpen={isSendWorklistPatientModalOpen} onClose={handleCloseModal}>
        <Send2WorklistForm onSubmit={handleCloseModal} patientData={selectedPatient} />
      </Modal>
    </>
  );
};

export default SearchedPatientsDatagrid;
