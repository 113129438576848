import Layout from "../Layouts/Layouts"

export default function AboutPage() { 
    return(
        <Layout> 
            <div className="flex flex-col items-center justify-center h-full bg-gray-900 p-8">
                <h1 className="text-4xl font-bold text-white mb-6">About Astra</h1>
                <div className="max-w-xl text-center text-white">
                    <p className="text-lg mb-4">
                        Welcome to <span className="font-semibold">Astra</span> – a cutting-edge software solution designed to revolutionize the way medical imaging is managed and accessed within hospital systems.
                    </p>
                    <p className="text-lg mb-4">
                        Built on the robust framework of .NET 9.0, Astra integrates seamlessly with SQL Server 2022, providing a reliable and secure database backend. Our software features a sophisticated PACS (Picture Archiving and Communication System) that ensures efficient storage, retrieval, and sharing of medical images.
                    </p>
                    <p className="text-lg mb-4">
                        Astra's RESTful API facilitates smooth communication between the PACS server and various DICOM-compliant devices, ensuring all components of the hospital's imaging network are interconnected. This integration streamlines workflows and enhances the accessibility of crucial medical data.
                    </p>
                    <p className="text-lg">
                        Experience the future of medical imaging with Astra – where technology meets healthcare to deliver unparalleled performance and reliability.
                    </p>
                </div>
            </div> 
        </Layout>
    );
}
