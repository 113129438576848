// Desc: WorklistDatagrid component to display worklist data in a grid
'use strict';
import React, { useMemo, useState, useEffect } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import './datagrid.css';
import { XCircleIcon } from '@heroicons/react/24/solid';

const handleDeleteWorklistEntry = async (accessionNumber) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/deleteworklistentry?accessionNumber=${accessionNumber}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      console.log('Entry deleted successfully');
      // Optionally, refresh the grid data here
    } catch (error) {
      console.error('Failed to delete entry:', error);
    }
  };

const ActionButtons = (p) => {
    return (
      <>
        <button
          onClick={() => handleDeleteWorklistEntry(p.data.accessionNumber)}
          className="flex items-center text-xs mt-2 text-red-500 hover:text-red-700"
        >
          <XCircleIcon className="h-5 w-5" />
        </button>
      </>
    );
  };

const WorklistDatagrid = ({ apiUrl }) => {
    const [rowData, setRowData] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(apiUrl);
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                const data = await response.json();
                const transformedData = data.map(item => ({
                    patId: item.patientIdentifier,
                    name: item.patientName,
                    dob: item.patientBirthDate,
                    sex: item.patientSex,
                    ethnicity: item.patientEthnicGroup,
                    Modality: item.modality,
                    StudyDate: item.examDate,
                    StudyTime: item.examTime,
                    AccessionNumber: item.accessionNumber,
                    ReferringPhysician: item.referringPhysician,
                    StudyDescription: item.studyDescription,
                    StudyInstanceUID: item.studyInstanceUid,
                    ScheduledProcedureDescription: item.examDescription,
                    ScheduledAETitle: item.scheduledAET
                }));
                setRowData(transformedData);
            } catch (error) {
                console.error('Error fetching data:', error);
                setError(error.message);
            }
        };

        fetchData();
    }, [apiUrl]);

    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            editable: false,
            filter: true,
        };
    }, []);

    const [colDefs, setColDefs] = useState([
        { field: "AccessionNumber", headerName: "Accession Number" },
        { field: "ScheduledProcedureDescription", headerName: "Scheduled Procedure Description" },
        {
            field: "patId",
            headerName: "Patient Identifier",
            
        },
        { field: "name", headerName: "Name" },
        // { field: "dob", headerName: "Date of Birth" },
        { field: "sex", headerName: "Sex" },
        { field: "ethnicity", headerName: "Ethnicity" },
        { field: "Modality", headerName: "Modality" },
        { field: "StudyDate", headerName: "Study Date" },
        { field: "StudyTime", headerName: "Study Time" },
        { field: "ScheduledAETitle", headerName: "Scheduled AE Title" },
        { field: "StudyInstanceUID", headerName: "Study Instance UID" },
        // {field: "actions", headerName: "Actions", cellRenderer: ActionButtons,},
    ]);

    return (
        <div className='ag-theme-quartz-dark h-screen bg-transparent'>
            <AgGridReact
                
                rowData={rowData}
                columnDefs={colDefs}
                pagination={true}
                rowSelection='multiple'
                paginationPageSize={20}
                defaultColDef={defaultColDef}
            />
        </div>

        
    );
}

export default WorklistDatagrid;
